import pdfIcon from "../assets/doc_icons/pdf.svg"
import imageIcon from "../assets/doc_icons/image.svg"
import docsIcon from "../assets/doc_icons/docx2.svg"
import excelIcon from "../assets/doc_icons/excel.svg"
import videoIcon from "../assets/doc_icons/video.svg"
import documentIcon from "../assets/doc_icons/document.svg"
import imageIcon2 from "../assets/doc_icons/image2.svg"
import filmIcon from "../assets/doc_icons/film.svg"
import { useFormikContext } from "formik"

const handleDateValues = (date, placeholder = "No Date") => {
  return date && String(date)?.trim()?.length > 0 ? date : placeholder
}

const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return pdfIcon; // Replace with actual path to your PDF icon image
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon; // Replace with actual path to your image icon
    case 'doc':
    case 'docx':
      return docsIcon; // Replace with actual path to your DOC/DOCX icon
    case 'xls':
    case 'xlsx':
    case 'csv':
      return excelIcon; // Replace with actual path to your Excel icon
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
      return videoIcon; // Replace with actual path to your video icon
    default:
      return docsIcon; // Replace with actual path to a default icon for unsupported types
  }
};

//   // Example usage
//   const fileName = 'example.pdf';
//   const iconPath = getFileIcon(fileName);
//   console.log(iconPath); // Outputs the path to the corresponding icon based on the file extension

const getFileCategory = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'ppt':
    case 'pptx':
    case 'txt':
      return documentIcon; // Category for document files

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon2; // Category for image files

    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
    case 'flv':
      return filmIcon; // Category for video files

    default:
      return documentIcon; // Default case for unsupported or unknown file types
  }
};

//   // Example usage
//   const fileName = 'example.mp4';
//   const fileCategory = getFileCategory(fileName);
//   console.log(fileCategory); // Outputs 'video'

const containerRef = (ref) => {
  if (ref) {
    // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
    ref._getBoundingClientRect = ref.getBoundingClientRect;

    ref.getBoundingClientRect = () => {
      const original = ref._getBoundingClientRect();

      return {
        ...original,
        width: Math.floor(original.width),
        height: Math.floor(original.height),
      };
    };
  }
}

const handleCopyClick = (copyLink, copied = false, setCopied) => {
  // Perform the copy action (e.g., copying text to clipboard)
  navigator.clipboard.writeText(copyLink);

  // Change the state to show the clicked (copied) icon
  setCopied(true);

  // Optionally reset the icon back after a delay
  setTimeout(() => setCopied(false), 2000);
};

const capitalizedSentence = (sentence) => sentence && sentence?.length > 0 ? sentence?.split(' ')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1)).join(' ') : '';

const avoidSpecialPattern = "^[a-zA-Z0-9\s\-\@\$\(\)\.]+$"

const currencyInputProps = {
  inputMode: "numeric", // Use numeric keyboard on mobile devices
  pattern: "[0-9]*", // Only allow digits 0-9
  // title: "Please enter a number between 0 and 9",
  style: {
    // textAlign: 'center', // Center the text in the input
  },
  min: 0,
  onKeyDown: (event) => {
    // Prevent the minus sign (-) from being entered
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Get the input value
    const inputValue = event.key;

    // Allow only numeric characters (0-9) and certain control keys
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(inputValue)) {
      // If not, prevent the key from being entered
      event.preventDefault();
    }
  },
  onInput: (event) => {
    // Get the input value
    const inputValue = event.target.value;

    // Remove non-numeric characters using a regular expression
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Update the input field value with the filtered numeric value
    event.target.value = numericValue;
  },
  onKeyDown: (event) => {
    // Prevent the input of minus sign (key code 189)
    if (event.keyCode === 189 || event.key === "-") {
      event.preventDefault();
    }
  },
  disableUnderline: true,
};

const linkedInTestURL = /linkedin\.com\/.*$/
const websiteTestURL = /^[a-zA-Z0-9.-]+\.(com|in|org|net|edu|gov|info|io|co|us|uk|de|fr|au|ca|jp|cn|ru|br|za|biz|online|store|tech|ai|app|xyz|site|me|tv|pro|name|club|live|today|news|digital|dev|global|solutions|agency|group|space|design|foundation|media|network|shop|systems|works|ventures|school|restaurant|market|coffee|chat)$/;
const downloadFile = (url) => {
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob(); // Convert the response to a Blob
    })
    .then(blob => {
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);

      // Extract the original filename from the URL
      const urlParts = url.split('/');
      const filename = urlParts[urlParts.length - 1] || 'downloaded_file';

      link.href = objectUrl;
      link.setAttribute('download', filename); // Use the extracted filename
      document.body.appendChild(link); // Append the link to the DOM
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up the DOM
      URL.revokeObjectURL(objectUrl); // Release the Blob URL
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
};

function stringAvatar(current_name) {
  let name = (current_name)?.length > 0 ? current_name?.replace(" ", "") : ""
  if (name && name?.includes(" ")) {
    return {
      sx: {
        bgcolor: "#F9F5FF",
        color: "#3538CD",
        width: "40px",
        height: "40px",
        fontSize: '1rem',
        textTransform: 'capitalize'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  } else if (name && !(name?.includes(" "))) {
    return {
      sx: {
        bgcolor: "#F9F5FF",
        color: "#3538CD",
        width: "40px",
        height: "40px",
        fontSize: '1rem',
        textTransform: 'capitalize'
      },
      children: name[0],
    };
  }
  else {
    return {
      sx: {
        bgcolor: "#F9F5FF",
        color: "#3538CD",
        width: "40px",
        height: "40px",
        fontSize: '1rem',
        textTransform: 'capitalize'
      },
      children: '',
    }
  }
}

const scrollToFirstVisibleValidation = () => {
  // Query all elements with the class "validation-message"
  const validationMessages = document.querySelectorAll('.validation-message');
  // console.log(validationMessages, "dddd")

  // Find the first visible validation message
  for (const message of validationMessages) {
    if (message && message.offsetParent !== null) {
      message.scrollIntoView({ behavior: 'smooth', block: 'center' });
      break;
    }
  }
};

const handleFormSubmit = async (e, formik) => {
  e.preventDefault();
  const isValid = await formik.validateForm(); // Validate form before submission

  if (Object.keys(isValid).length > 0) {
    // If validation errors exist, trigger scroll
    formik.setTouched(isValid); // Mark all fields as touched
    setTimeout(() => {
      scrollToFirstVisibleValidation();
    }, 10)
  } else {
    formik.handleSubmit(); // Submit if there are no errors
  }
}

function convertCurrencyAmountRange(amount, currency, noofDecimal = 2) {
  let result = '';

  // Check the currency type
  if (currency === 'INR') {
    if (amount >= 10000000) {
      result = (amount / 10000000).toFixed(noofDecimal) + ' Cr'; // Crores (10 million)
    } else if (amount >= 100000) {
      result = (amount / 100000).toFixed(noofDecimal) + ' L'; // Lakhs (100 thousand)
    } else if (amount >= 1000) {
      result = (amount / 1000).toFixed(noofDecimal) + ' K'; // Thousands
    } else {
      result = amount.toString(); // No conversion for smaller amounts
    }
  } else if (currency === 'USD') {
    if (amount >= 1000000) {
      result = (amount / 1000000).toFixed(noofDecimal) + 'M'; // Millions
    } else if (amount >= 1000) {
      result = (amount / 1000).toFixed(noofDecimal) + 'K'; // Thousands
    } else {
      result = amount.toString(); // No conversion for smaller amounts
    }
  } else {
    result = 'Invalid currency type';
  }

  return result;
}

export { handleDateValues, getFileIcon, getFileCategory, containerRef, capitalizedSentence, handleCopyClick, avoidSpecialPattern, currencyInputProps, stringAvatar, handleFormSubmit, linkedInTestURL, websiteTestURL, convertCurrencyAmountRange ,downloadFile}