import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import PerfectScrollbar from "react-perfect-scrollbar"
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import { useTheme } from '@emotion/react'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { Box, Button, Grid, Tooltip, useMediaQuery } from '@mui/material'
import { makeStyles } from "@mui/styles"
import NeedHelpButton from 'common-components/NeedHelpButton'
import { TabContext } from '@mui/lab'
import CustomTabs from 'ui-components/CustomTabs'
import { ArchiveOutlined, ArchiveRounded, Done, DoneRounded, LanguageRounded, PublishedWithChanges, RemoveRedEyeRounded, ShareRounded } from '@mui/icons-material'
import AsyncTableListing from 'ui-components/AsyncTableListing'
import DeleteConfirmationDialog from '../../ui-components/popups/ConfirmationPopup'

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        '& > .Mui-disabled.MuiTabs-scrollButtons': {
            display: 'none',
            transition: 'all 1s ease'
        }
    }
}));

const allInvestorData = {
    data: [
        {
            name: 'IIMA Ventures',
            image: 'https://iimaventures.com/wp-content/uploads/2024/01/IIMA-Logo.png',
            type: 'Incubator',
            location: 'Ahemdabad, Gujrat, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'D2C (Direct-to-Consumer), C2B (Consumer-to-Business)',
            cheque_size: '$500 to $1000k USD',
            public_profile_url: 'https://backrr.in/investor/incubator'
        },
        {
            name: 'Kalaari',
            image: 'https://kalaari.com/wp-content/uploads/2021/08/Kalaari-Logo.png',
            type: 'VC',
            location: 'Bengaluru, Karnataka, India',
            focused_categories: 'Technology, Marketplace',
            focused_sectors: 'Information Technology, Cosmetics Industry',
            focused_business_models: 'D2C (Direct-to-Consumer), C2B (Consumer-to-Business)',
            cheque_size: '$10 to $250k USD',
            public_profile_url: 'https://backrr.in/investor/incubator'
        },
        {
            name: 'Fluid Ventures',
            image: 'https://fluidvc.in/wp-content/uploads/2022/01/fluidventures.png',
            type: 'Micro VC',
            location: 'Gurugram, Haryana, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'C2B (Consumer-to-Business)',
            cheque_size: '$20 to $50k USD',
            public_profile_url: 'https://backrr.in/investor/incubator'
        },
        {
            name: 'Vaibhav Dixit',
            type: 'VC',
            location: 'Mubmai, Maharashtra, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry, Mobile Accessories',
            focused_business_models: 'C2B (Consumer-to-Business)',
            cheque_size: '$100 to $250k USD'
        },
        {
            name: 'Rahul Goel',
            type: 'Accelerator',
            location: 'Ahemdabad, Gujrat, India',
            focused_categories: 'Technology, Consumer Brand',
            focused_sectors: 'Information Technology, Cosmetics Industry',
            focused_business_models: 'B2B (Business-to-Business)',
            cheque_size: '$50 to $200k USD'
        },
        {
            name: 'Ajay Sharma',
            type: 'Accelerator',
            location: 'Ahemdabad, Gujrat, India',
            focused_categories: 'Marketplace, Services',
            focused_sectors: 'Cosmetics Industry',
            focused_business_models: 'C2B (Consumer-to-Business)',
            cheque_size: '$150 to $200k USD'
        },
        {
            name: 'Pranay Mathur',
            image: 'https://backrr.in/static/media/pranay.0b5bd68b15473e8149a3.png',
            type: 'Angel',
            location: 'Jaipur, Rajasthan, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'B2B (Business-to-Business)',
            cheque_size: '$100 to $600k USD',
            public_profile_url: 'https://backrr.in/investor/incubator'
        },
        {
            name: 'Pepper Tree',
            image: 'https://static.wixstatic.com/media/fff1db_cd63bc88f8504963bbbd440f9ddfa89a~mv2.png/v1/fill/w_450,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/Untitled-3.png',
            type: 'Investment Banker',
            location: 'Mumbai, Maharashtra, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'C2B (Consumer-to-Business)',
            cheque_size: '$50 to $200k USD',
            public_profile_url: 'https://backrr.in/investor/incubator'
        },
        {
            name: 'Vipul Shah',
            type: 'Accelerator',
            location: 'Surat, Gujrat, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Cosmetics Industry',
            focused_business_models: 'B2B (Business-to-Business)',
            cheque_size: '$20 to $100k USD'
        },
        {
            name: 'GSF',
            type: 'Incubator',
            location: 'Jaipur, Rajasthan, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'C2B (Consumer-to-Business)',
            cheque_size: '$5 to $20k USD'
        },
        {
            name: 'NOTO',
            type: 'Micro VC',
            location: 'Nasik, Maharashtra, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'B2B (Business-to-Business), C2B (Consumer-to-Business)',
            cheque_size: '$10 to $250k USD'
        },
        {
            name: 'Redwing',
            type: 'Investment Banker',
            location: 'Kolkata, West Bengal, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'D2C (Direct-to-Consumer)',
            cheque_size: '$100 to $500k USD'
        },
        {
            name: 'Eske',
            type: 'Accelerator',
            location: 'Chennai, Tamil Nadu, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'B2G (Business-to-Government)',
            cheque_size: '$20 to $300k USD'
        },
        {
            name: 'Bummer',
            type: 'VC',
            location: 'Ranchi, Jharkhand, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'P2P (Peer-to-Peer), C2B (Consumer-to-Business)',
            cheque_size: '$50 to $100k USD'
        },
        {
            name: 'Koparo',
            type: 'Accelerator',
            location: 'Delhi, New Delhi, India',
            focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
            focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
            focused_business_models: 'B2B (Business-to-Business)',
            cheque_size: '$10 to $200k USD'
        }
    ],
    limit: 10,
    count: 2356
}

const investorsTab = [
    { value: "all_investors", label: 'All Investors', count: 10, read: false, icon: PublishedWithChanges },
    { value: "shortlisted", label: 'Shortlisted', count: 10, read: false, icon: DoneRounded },
    { value: "shared_pitchdeck", label: 'PitchDeck Shared', count: 10, read: false, icon: ShareRounded },
    { value: "archived", label: 'Archived', count: 10, read: false, icon: ArchiveOutlined },
]

const FindInvestors = () => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const [value, setValue] = useState("all_investors");
    const [transformedData, setTransformedData] = useState(allInvestorData)
    const [shortlistedInvestorData, setShortlistedInvestorData] = useState({ data: [], limit: 10, count: 0 })
    const [sharedPitchDeckInvestorData, setSharedPitchDeckInvestorData] = useState({ data: [], limit: 10, count: 0 })
    const [archivedInvestorData, setArchivedInvestorData] = useState({ data: [], limit: 10, count: 0 })

    const [currentRow, setCurrentRow] = useState(null)

    const [openConfirmedShortlistedPopup, setOpenConfirmedShortlistedPopup] = useState(false);
    const [openConfirmedSharePitchdeckPopup, setOpenConfirmedSharePitchdeckPopup] = useState(false);
    const [openConfirmedArchivedPopup, setOpenConfirmedArchivedPopup] = useState(false);

    const InvestorsColumns = [
        { Header: 'Investor Name', accessor: 'name', minWidth: 200, align: 'start', fixed: true },
        { Header: 'Type', accessor: 'type', minWidth: 70, align: 'center' },
        { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
        { Header: 'Categories', accessor: 'focused_categories', minWidth: 200, align: 'start', tooltip: "focused_categories" },
        { Header: 'Sectors', accessor: 'focused_sectors', minWidth: 200, align: 'start', tooltip: "focused_sectors" },
        { Header: 'Business Models', accessor: 'focused_business_models', minWidth: 200, align: 'start', tooltip: "focused_business_models" },
        { Header: 'Cheque Size', accessor: 'cheque_size', minWidth: 150, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100 },
    ];

    const Actions = () => {
        return (
            <>
                <NeedHelpButton />
            </>
        );
    };

    const columns = useMemo(
        () => InvestorsColumns
        ,
        [value]
    );

    const RowActions = ({ row }) => {

        const isSelected = currentRow?.name === row?.original?.name;

        const handleOpenConfirmedShortlistedPopup = (row) => {
            setCurrentRow(row)
            setOpenConfirmedShortlistedPopup(true);
        }

        const handleOpenConfirmedSharePitchdeckPopup = (row) => {
            setCurrentRow(row)
            setOpenConfirmedSharePitchdeckPopup(true);
        }

        const handleOpenConfirmedArchivedPopup = (row) => {
            setCurrentRow(row)
            setOpenConfirmedArchivedPopup(true);
        }

        const handleCloseConfirmedSharePitchdeckPopup = () => {
            setCurrentRow(null)
            setOpenConfirmedSharePitchdeckPopup(false);
        }

        const handleCloseConfirmedShortlistedPopup = () => {
            setCurrentRow(null)
            setOpenConfirmedShortlistedPopup(false);
        }

        const handleCloseConfirmedArchivedPopup = () => {
            setCurrentRow(null)
            setOpenConfirmedArchivedPopup(false);
        }

        const handleSubmitConfirmedShortlistedPopup = () => {
            let shortlisted = shortlistedInvestorData
            console.log("shortlisted", shortlisted)
            shortlisted.limit = 10
            shortlisted.count = shortlisted.count + 1
            shortlisted.data.push(currentRow)
            setShortlistedInvestorData(shortlisted)
            setOpenConfirmedShortlistedPopup(false);
        }

        const handleSubmitConfirmedSharedPitchdeckPopup = () => {
            let shared = sharedPitchDeckInvestorData
            shared.limit = 10
            shared.count = shared.count + 1
            shared.data.push(currentRow)
            setSharedPitchDeckInvestorData(shared)
            handleCloseConfirmedSharePitchdeckPopup(false);
        }

        const handleSubmitConfirmedArchivedPopup = () => {
            let archived = archivedInvestorData
            archived.limit = 10
            archived.count = archived.count + 1
            archived.data.push(currentRow)
            setArchivedInvestorData(archived)
            handleCloseConfirmedArchivedPopup(false);
        }

        return <>
            {isSelected && <DeleteConfirmationDialog open={openConfirmedShortlistedPopup} handleClose={handleCloseConfirmedShortlistedPopup} handleSubmission={handleSubmitConfirmedShortlistedPopup} heading={"Confirm Shortlisted"} ActionText={"Confirm"} subheading={"Are you sure you want to move to shortlisted investors?"} />}
            {isSelected && <DeleteConfirmationDialog open={openConfirmedSharePitchdeckPopup} handleClose={handleCloseConfirmedSharePitchdeckPopup} handleSubmission={handleSubmitConfirmedSharedPitchdeckPopup} heading={"Confirm Share PitchDeck"} ActionText={"Confirm"} subheading={"Are you sure you want to share PitchDeck with investor?"} />}
            {isSelected && <DeleteConfirmationDialog open={openConfirmedArchivedPopup} handleClose={handleCloseConfirmedArchivedPopup} handleSubmission={handleSubmitConfirmedArchivedPopup} heading={"Confirm Archive"} ActionText={"Confirm"} subheading={"Are you sure you want to move to archived investors?"} />}
            <Box display={"flex"} gap={1}>
                <Tooltip title={"Public Profile"}>
                    <Button disabled = {row.original.public_profile_url?.trim()?.length == 0} sx={{ minWidth: "1rem" }} onClick={() => { row.original.public_profile_url?.trim()?.length > 0 && window.open(row.original.public_profile_url) }}>
                        <RemoveRedEyeRounded sx={{ color: row.original.public_profile_url?.trim()?.length > 0 ? theme.palette.customColors.indigo700 : theme.palette.customColors.disabled }} />
                    </Button>
                </Tooltip>
                {value != "shortlisted" && <Tooltip title={"Move to Shortlisted"}>
                    <Button sx={{ minWidth: "1rem" }} onClick={() => handleOpenConfirmedShortlistedPopup(row.original)}>
                        <Done sx={{ color: theme.palette.customColors.indigo700 }} />
                    </Button>
                </Tooltip>
                }
                {value != "shared_pitchdeck" && <Tooltip title={"Share PitchDeck"}>
                    <Button sx={{ minWidth: "1rem" }} onClick={() => handleOpenConfirmedSharePitchdeckPopup(row.original)}>
                        <ShareRounded sx={{ color: theme.palette.customColors.indigo700 }} />
                    </Button>
                </Tooltip>
                }
                {value != "archived" && <Tooltip title={"Archive"}>
                    <Button sx={{ minWidth: "1rem" }} onClick={() => handleOpenConfirmedArchivedPopup(row.original)}>
                        <ArchiveOutlined sx={{ color: theme.palette.customColors.indigo700 }} />
                    </Button>
                </Tooltip>
                }
            </Box>
        </>
    }

    const handleTabChange = (event, newValue) => {
        // console.log("value", newValue)
        setValue(newValue);
        switch (newValue) {
            case 'all_investors': {
                setTransformedData(allInvestorData)
                break
            }
            case 'shortlisted': {
                setTransformedData(shortlistedInvestorData)
                break
            }
            case 'shared_pitchdeck': {
                setTransformedData(sharedPitchDeckInvestorData)
                break
            }
            case 'archived': {
                setTransformedData(archivedInvestorData)
                break
            }
            default: {
                setTransformedData(allInvestorData)
                break
            }
        }
    };

    return (
        <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
            <BreadcrumbHeader
                title={() => (
                    <>
                        {isSmallScreen
                            ? "Find Investors"
                            : "Find Investors"}{" "}
                    </>
                )}
                Actions={Actions}
                sx={{ paddingBottom: 0 }}
                titleStyle={
                    { marginTop: "0.4rem" }
                }
            />
            <>
                <Grid xs={12} container bgColor={theme.palette.customColors.yellow_bg} height={"100%"}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <PerfectScrollbar style={{ height: '100%', maxHeight: "calc(100vh - 180px)", paddingBottom: '0.5rem' }}>
                            <>
                                <TabContext value={value}>
                                    <Grid container xs={12} pt={1} pb={0} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
                                        <Grid item xs={12} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0rem"} >
                                            <Grid xs={12} container sx={{ bgcolor: 'white', borderRadius: '8px 8px 0 0', borderInline: `1px solid ${theme.palette.customColors.b50}`, borderBlockStart: `1px solid ${theme.palette.customColors.b50}` }}>
                                                <Grid item xs={12}>
                                                    <CustomTabs
                                                        variant="submenu"
                                                        titleStyle={{ fontSize: '1rem' }}
                                                        tabStyle={{ padding: '0.5rem 1rem 0rem' }}
                                                        sx={{ border: 'none' }}
                                                        bgColor={"transparent"}
                                                        data={
                                                            [...investorsTab?.map(item => {
                                                                return ({ ...item, label: `${item?.label} ${value === item?.value && transformedData?.count ? `(${transformedData?.count})` : ""}` })
                                                            })]
                                                        } value={value} handleChange={handleTabChange}
                                                        isSmallScreen={isSmallScreen} className={classes.scrollTabs} key={"Tabs Tabs"} indicatorFullSize={false} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Box width={"100%"} paddingLeft={"2rem"} maxWidth={"100%"} overflow={"hidden"} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                                            <AsyncTableListing
                                                columns={columns}
                                                tabValue={value}
                                                data={transformedData?.data}
                                                actions={Actions}
                                                page={page}
                                                setPage={setPage}
                                                limit={transformedData?.limit}
                                                setLimit={setLimit}
                                                count={transformedData?.count}
                                                RowActions={RowActions}
                                                sx={{ borderRadius: '0 0 8px 8px', border: `1px solid ${theme.palette.customColors.b50}` }}
                                            />
                                        </Box>

                                    </Grid>
                                </TabContext>
                            </>
                        </PerfectScrollbar>
                    </Box>
                </Grid>
            </>
            {/* } */}
        </ThemeBGWrapper>
    )
}

export default FindInvestors