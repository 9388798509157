import { Avatar, Box, Button, Card, Chip, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BLUEBORDER, WHITEBG } from '../../../ui-components/CustomButton';
import { ReactComponent as EditIcon } from "../../../assets/rounds_icons/edit_icon.svg";
import PieChart from '../../../ui-components/PieChart'
import { useTheme } from '@emotion/react';
import { handleDateValues } from '../../../utilities';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ThumbnailGenerator from 'ui-components/ThumbnailGenerator';
import moment from 'moment';
import { ReactComponent as UploadOutlinedIcon } from "../../../assets/upload-cloud-icon-outlined.svg"
import { B400 } from 'ui-components/CustomInputButton';
import placeHolderImage from "assets/placeholder_image.png"
import { Add, Language } from '@mui/icons-material';
import indianFlag from "assets/image.png"
import PDFView from './popups/PDFView';
const PersonDetails = ({ theme, data, index, length }) => {

    const isSmallScreen = (useMediaQuery(theme.breakpoints.down('sm')))[0];
    const isTableScreen = (useMediaQuery(theme.breakpoints.up('sm')));
    return <Grid xs={12} sm={12} sx={{
        padding: '0rem', boxShadow: 'none', borderRadius: "8px",
        // border: `1px solid ${theme.palette.customColors.gray300}`,
        overflow: 'hidden', paddingTop: 1, display: 'flex', flexDirection: "row", gap: "1.5rem",
    }}>
        {data?.image ? <img src={data?.image} style={{ flex: isTableScreen ? 0 : 0.3, minHeight: 100, height: 100, maxHeight: 100, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", marginBottom: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} /> : <Avatar sx={{ minHeight: 100, height: 100, maxHeight: 100, width: 100, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} />}
        <Box display={"grid"} gap={1} sx={{ placeContent: "start", flex: 1 }}>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"flex-start"} marginInline={0} gap={1}>
                {data?.name && <Typography textAlign={"start"} sx={{ fontWeight: 600 }}>{data?.name}  {data?.type && `${data?.type}`}</Typography>}
                {data?.linkedin_url && <LinkedInIcon sx={{ fontSize: 20 }} style={{ display: 'inline-block', marginInlineEnd: 0, color: `${theme.palette.customColors.indigo600}` }} onClick={() => { window.open(data?.linkedin_url) }} />}
            </Grid>
            {data?.bio && <Typography textAlign={"start"} sx={{ marginInline: 0 }}>{data?.bio}</Typography>}
        </Box>
    </Grid>
}

const CardContentList = ({ data = [], style = {} }) => {
    const theme = useTheme();
    return <>
        <Grid
            container
            xs={12}
            sx={{
                // border: `1px solid ${theme?.palette?.customColors?.b75}`,
                border: '1px solid rgb(208, 213, 221)',
                borderRadius: "8px",
                overflow: "hidden",
                ...style
            }}
        >
            {
                data?.map(({ label, value }, index) => {
                    const isHighlighted = index % 2 === 0;
                    return (

                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                            // alignItems="center"
                            sx={{
                                padding: "12px 16px",
                                gap: '1rem',
                                // backgroundColor: isHighlighted ? (theme) => theme.palette.customColors.b50 : 'transparent',
                                backgroundColor: isHighlighted ? "rgb(246, 246, 246)" : 'transparent',
                            }}
                        >
                            <Typography>{label}</Typography>
                            <Typography textAlign={"end"}>{value ? value : '-'}</Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    </>
}


const CardHeader = ({ title, action, actionText, actionStyle = {} }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
        {action && <WHITEBG
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                border: `1px solid #D0D5DD !important`,
                display: "flex",
                gap: '0.5rem',
                paddingInline: '0.5rem 0.875rem',
                ...actionStyle,
            }}
            onClick={action}
        >
            <EditIcon
                style={{
                    width: 20,
                    height: 20,
                }} />
            <Typography>
                {actionText ? actionText : "Edit"}
            </Typography>
        </WHITEBG>}
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "" }) => {
    const [open, setOpen] = React.useState(false);

    let content = open ? htmlContent : htmlContent?.substring(0, 300);

    return (
        <>
            {content && content?.length > 0 ?
                <>
                    <div dangerouslySetInnerHTML={{ __html: content }} className='rich-text' style={{ color: color, textAlign: 'justify' }} />
                    {htmlContent?.length > 300 &&
                        <BLUEBORDER
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            sx={{
                                backgroundColor: 'transparent',
                                border: "0",
                                padding: "0"
                            }}
                            onClick={() => setOpen(!open)}
                        > <div>{!open ? 'See more' : 'See less'}</div>
                        </BLUEBORDER>
                    }
                </>
                : "-"
            }
        </>
    );
};

const ViewDetails = ({ data: startupDetail, round_id }) => {
    // console.log("startupDetail", startupDetail)
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    let founders = startupDetail?.founder != null ? [
        startupDetail?.founder, ...startupDetail?.coFoundersArray
    ] : startupDetail?.coFoundersArray

    const startupInformation = [
        // { label: "Startup Name", value: startupDetail?.startup_name ? startupDetail?.startup_name : '-' },
        { label: "Legal Entity Name", value: startupDetail?.entity_name ? startupDetail?.entity_name : '-' },
        { label: "Formation Date", value: startupDetail?.formation_date != null && startupDetail?.formation_date ? startupDetail?.formatted_formation_date : "-" },
        { label: "Stage", value: startupDetail?.stage_info?.name ? startupDetail?.stage_info?.name : "-" },
        { label: "Sector", value: `${startupDetail?.category_info?.name ? startupDetail?.category_info?.name : "-"} ${startupDetail?.sector_info[0]?.sub_category ? " (" + startupDetail?.sector_info[0]?.sub_category + ")" : ""}` },
        {
            label: "Business Model",
            value: <>
                {startupDetail?.business_model_info && startupDetail?.business_model_info?.length > 0
                    ? startupDetail?.business_model_info?.length > 1
                        ?
                        <Tooltip title={startupDetail?.business_model_info?.map((item) => <span> {item?.name} <br /></span>)}>
                            <Chip label={startupDetail?.business_model_info[0]?.name + " + " + String(startupDetail?.business_model_info?.length - 1)} />
                        </Tooltip>
                        : startupDetail?.business_model_info[0]?.name : '-'}
            </>
            // `${startupDetail?.business_model_info?.name ? startupDetail?.business_model_info?.name : "-"}`
        },
    ]

    const contactInformation = [
        { label: "Full Name", value: startupDetail?.first_name + " " + startupDetail?.last_name },
        { label: "Mobile", value: `${startupDetail?.phone_code} - ${startupDetail?.mobile_number}` },
        { label: "Location", value: `${startupDetail?.city}, ${startupDetail?.state}, ${startupDetail?.country}` },
        { label: "Email", value: startupDetail?.email ? `${startupDetail?.email}` : "-" },
        {
            label: "Website URL", value:
                <>
                    {startupDetail?.website_url ? <Link target="_blank" to={"https://" + startupDetail?.website_url} variant="body1">
                        {startupDetail?.website_url}
                    </Link> : <>-</>}
                </>
        },
        {
            label: "Startup LinkedIn Page", value:
                <>
                    {startupDetail?.linkedin_url ? <Link target="_blank" to={"https://" + startupDetail?.linkedin_url} variant="body1">
                        {startupDetail?.linkedin_url}
                    </Link> : <>-</>}
                </>
        },
    ]


    const [selectedPDF, setSelectedPDF] = React.useState(null)


    const handleOpenPDFViewer = ({ pitch_deck_path }) => {
        // console.log(handleOpenPDFViewer)
        setSelectedPDF(pitch_deck_path);
    }

    const handleClosePDFViewer = () => {
        setSelectedPDF(null);
    }


    return (
        <Grid item container xs={12} spacing={"1.25rem"} display={"flex"} sx={{ paddingBottom: '2rem', flexDirection: "row" }}
            //  gridTemplateAreas={isLargeScreen ? `"first aside" "second aside" "third aside" "forth aside" "fifth aside" "sixth aside"` : `"first" "aside" "second" "third" "forth" "fifth" "sixth"`}
            // gridTemplateColumns={isLargeScreen ? "2.5fr 1.5fr" : '1fr'} 
            justifyContent={"space-between"}>
            <Grid item container xs={12} sm={12} md={7} lg={8} spacing={"1.25rem"} alignSelf={"flex-start"}>
                {/* <Grid sx={{ gridArea: 'first', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                        <CardHeader title={"Startup Information"} action={() => { navigate(`/profile/update?page=1`) }} />
                        <Grid xs={12} item container spacing={2} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                {startupDetail?.startup_logo ? <img src={startupDetail?.startup_logo} style={{ height: isLargeScreen ? 150 : 110, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} /> :
                                    <Box
                                        component="img"
                                        src={placeHolderImage}
                                        alt="Uploaded Preview"
                                        loading='eager'
                                        rel='preload'
                                        sx={{
                                            padding: "1rem",
                                            height: isLargeScreen ? 150 : 110, aspectRatio: 1, objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center'
                                        }}
                                    />}
                            </Grid><Grid item xs={12} sm={9} md={8} lg={9}>
                                <CardContentList
                                    data={startupInformation} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid> */}
                <PDFView open={Boolean(selectedPDF != null && selectedPDF?.length > 0)} pdfUrl={selectedPDF} handleClose={handleClosePDFViewer} handleFeedbackPopupOpen={() => { }} handleShowInterestPopupOpen={() => { }} currentRow={null} isEnabledFeedback={false} isEnabledInterest={false} title={"Pitch Deck"} />
                <Grid sx={{ gridArea: 'second', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                        <CardHeader action={() => { navigate(`/profile/update?page=1`) }} />
                        <Grid container xs={12} alignItems={"center"} spacing={2} pb={2} marginInline={"auto"} position={"sticky"} top={0} sx={{ zIndex: 1 }}>
                            <Grid item xs={12} pl={"0 !important"}>
                                {startupDetail?.startup_logo ? <img src={startupDetail?.startup_logo} style={{ height: isLargeScreen ? 150 : 110, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `0px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} /> :
                                    <Box
                                        component="img"
                                        src={placeHolderImage}
                                        alt="Uploaded Preview"
                                        loading='eager'
                                        rel='preload'
                                        sx={{
                                            padding: "1rem",
                                            height: 100, aspectRatio: 1, objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `0px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center'
                                        }}
                                    />}
                            </Grid>
                            <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                {startupDetail?.startup_name && <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.50rem" }, textAlign: 'center', marginInline: 'auto' }}>{startupDetail?.startup_name}</Typography>}
                            </Grid>
                            {(startupDetail?.city || startupDetail?.country) && <Typography variant='body2' sx={{ fontSize: { xs: "0.8rem", md: "0.8rem" }, textAlign: 'center', marginInline: 'auto' }}>{startupDetail?.city}{startupDetail?.city && startupDetail?.country && ","} {startupDetail?.country}
                                {startupDetail?.country?.trim()?.length > 0 && startupDetail?.country?.trim() == "India" && <span>
                                    <img src={indianFlag} rel='preload' style={{ width: "1.2rem", marginLeft: '5px' }} />
                                </span>}
                            </Typography>}

                            <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                {startupDetail?.linkedin_url && <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LinkedInIcon sx={{ fontSize: 30, borderRadius: 10 }} style={{ display: 'inline-block' }} onClick={() => { window.open("https://" + startupDetail?.linkedin_url) }} /></button>}
                                {startupDetail?.website_url && <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><Language sx={{ fontSize: 30 }} style={{ display: 'inline-block' }} onClick={() => { window.open("https://" + startupDetail?.website_url) }} /></button>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mb={"1.25rem"}>
                            <CardContentList
                                data={startupInformation} />
                        </Grid>
                        <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                            <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={1}>
                                What problem are you solving?
                            </Typography>
                            <RichTextDisplay htmlContent={startupDetail?.problem_solving} />
                        </Card>
                        <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                            <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={1}>
                                Startup Overview
                            </Typography>
                            <RichTextDisplay htmlContent={startupDetail?.description} />
                        </Card>
                    </Card>
                </Grid>
                {!isLargeScreen && <Grid sx={{ gridArea: 'aside', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                    {<Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                        <Grid item xs={12} paddingRight={0} marginTop={0}>
                            <Card sx={{ padding: '1rem 0', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, border: `0px solid ${theme.palette.customColors.g75}` }}>
                                <CardHeader title={"Founders"} action={() => { navigate(`/profile/update?page=3`) }} />
                                <Grid container xs={12} gap={1} alignItems={"flex-start"}>
                                    {founders?.length > 0 &&
                                        founders?.map((person, index) => {
                                            return <PersonDetails theme={theme} data={person} key={index} index={index} length={founders?.length} />
                                        })
                                    }
                                    {founders?.length === 0 && <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 284, width: '100%' }}>
                                        <B400
                                            sx={{
                                                background: theme.palette.customColors.indigo700,
                                                padding: "0.625rem 1rem"
                                            }}
                                            startIcon={
                                                <Add style={{ width: 20, height: 20 }} />
                                            }
                                            onClick={() => { navigate(`/profile/update?page=3`); }}
                                        >
                                            Add Founder(s) Details
                                        </B400>

                                    </Card>}
                                </Grid>
                            </Card>
                        </Grid>
                    </Card>}
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginTop: "1.25rem", paddingLeft: 0 }}>
                        <Grid
                            container
                            xs={12}
                            sx={{
                                border: `0px solid ${theme?.palette?.customColors?.b75}`,
                                borderRadius: "8px",
                                overflow: "hidden",
                            }}
                        >
                            {startupDetail?.pitch_deck_path ?
                                <Grid item xs={12}
                                    sm={6} md={12}
                                    paddingLeft={"0 !important"} paddingRight={"0"} marginTop={0}>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: 0, }}>
                                        <CardHeader title={"Pitch Deck"} action={() => { navigate(`/profile/update?page=2`) }} />
                                        <ThumbnailGenerator url={startupDetail?.pitch_deck_path} height={300} thumbnail={startupDetail?.pitch_deck_thumbnail_path || null} action={() => handleOpenPDFViewer({ pitch_deck_path: startupDetail?.pitch_deck_path })} />
                                    </Card>
                                </Grid> : <Grid item xs={12}
                                    sm={6} md={12}
                                    paddingLeft={"0 !important"} paddingRight={0}>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '15px' }}>
                                        <CardHeader title={"Pitch Deck"} action={() => { navigate(`/profile/update?page=2`) }}
                                        // sx={{ fontSize: "1rem" }}
                                        />
                                        <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 274 }}>
                                            <B400
                                                sx={{
                                                    background: theme.palette.customColors.indigo700,
                                                    padding: "0.625rem 1rem"
                                                }}
                                                startIcon={
                                                    <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
                                                }
                                                onClick={() => { navigate(`/profile/update?page=2`); }}
                                            >
                                                Upload
                                            </B400>
                                        </Card>
                                    </Card>
                                </Grid>}
                        </Grid>
                    </Card>
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginTop: "1.25rem", paddingLeft: 0 }}>
                        <Grid
                            container
                            xs={12}
                            sx={{
                                border: `0px solid ${theme?.palette?.customColors?.b75}`,
                                borderRadius: "8px",
                                overflow: "hidden",
                            }}
                        >
                            {startupDetail?.elevator_pitch_path ?
                                <Grid item xs={12}
                                    sm={6} md={12}
                                    paddingLeft={"0 !important"} paddingRight={0}>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '0' }}>
                                        <CardHeader title={"Video Pitch"}
                                            action={() => { navigate(`/profile/update?page=2`) }}
                                        // sx={{ fontSize: "1rem" }}
                                        />
                                        <ThumbnailGenerator height={300} type="video" url={startupDetail?.elevator_pitch_path} />
                                    </Card>
                                </Grid> : <Grid item xs={12}
                                    sm={6} md={12}
                                    paddingLeft={"0 !important"} paddingRight={0}>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '0' }}>
                                        <CardHeader title={"Video Pitch"} action={() => { navigate(`/profile/update?page=2`) }}
                                        // sx={{ fontSize: "1rem" }}
                                        />
                                        <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 284 }}>
                                            <B400
                                                sx={{
                                                    background: theme.palette.customColors.indigo700,
                                                    padding: "0.625rem 1rem"
                                                }}
                                                startIcon={
                                                    <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
                                                }
                                                onClick={() => { navigate(`/profile/update?page=2`); }}
                                            >
                                                Upload
                                            </B400>

                                        </Card>
                                    </Card>
                                </Grid>}
                        </Grid>
                    </Card>
                </Grid>}

                {<Grid sx={{ gridArea: 'third', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                        <CardHeader title={"Contact Information"} action={() => { navigate(`/profile/update?page=0`) }} />
                        <CardContentList
                            data={contactInformation} />
                        {/* <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Full Name</Typography>
                            <Typography>
                                {startupDetail?.first_name + " " + startupDetail?.last_name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Mobile</Typography>
                            <Typography>
                                {startupDetail?.phone_code} - {startupDetail?.mobile_number}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Location</Typography>
                            <Typography textAlign={"end"}>
                                {startupDetail?.city}, {startupDetail?.state}, {startupDetail?.country}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Website Url</Typography>
                            <Typography>
                                <Link target="_blank" to={"https://" + startupDetail?.website_url} variant="body1">
                                    {startupDetail?.website_url}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Startup LinkedIn Page</Typography>
                            <Typography>
                                <Link target="_blank" to={"https://" + startupDetail?.linkedin_url} variant="body1">
                                    {startupDetail?.linkedin_url}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid> */}
                    </Card>
                </Grid>}
            </Grid>
            {isLargeScreen && <Grid xs={12} sm={12} md={5} lg={4} sx={{ marginInline: { xs: 'auto', sm: 0 } }} item>
                {<Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <Grid item xs={12} paddingRight={0} marginTop={0}>
                        <Card sx={{ padding: '1rem 0', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, border: `0px solid ${theme.palette.customColors.g75}` }}>
                            <CardHeader title={"Founders"} actionStyle={founders?.length > 0 ? {} : { visibility: "hidden" }} action={() => { navigate(`/profile/update?page=3`) }} />
                            <Grid container xs={12} gap={1} alignItems={"flex-start"}>
                                {founders?.length > 0 &&
                                    founders?.map((person, index) => {
                                        return <PersonDetails theme={theme} data={person} key={index} index={index} length={founders?.length} />
                                    })
                                }
                                {founders?.length === 0 && <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 284, width: '100%' }}>
                                    <B400
                                        sx={{
                                            background: theme.palette.customColors.indigo700,
                                            padding: "0.625rem 1rem"
                                        }}
                                        startIcon={
                                            <Add style={{ width: 20, height: 20 }} />
                                        }
                                        onClick={() => { navigate(`/profile/update?page=4`); }}
                                    >
                                        Add Founder(s) Details
                                    </B400>

                                </Card>}
                            </Grid>
                        </Card>
                    </Grid>
                </Card>}
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginTop: "1.25rem", paddingLeft: 0 }}>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `0px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >

                        {startupDetail?.pitch_deck_path ?
                            <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={"0"} marginTop={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: 0, }}>
                                    <CardHeader title={"Pitch Deck"} action={() => { navigate(`/profile/update?page=2`) }} />
                                    <ThumbnailGenerator url={startupDetail?.pitch_deck_path} height={300} thumbnail={startupDetail?.pitch_deck_thumbnail_path || null} action={() => handleOpenPDFViewer({ pitch_deck_path: startupDetail?.pitch_deck_path })} />
                                </Card>
                            </Grid> : <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '15px' }}>
                                    <CardHeader title={"Pitch Deck"} action={() => { navigate(`/profile/update?page=2`) }}
                                    // sx={{ fontSize: "1rem" }}
                                    />
                                    <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 274 }}>
                                        <B400
                                            sx={{
                                                background: theme.palette.customColors.indigo700,
                                                padding: "0.625rem 1rem"
                                            }}
                                            startIcon={
                                                <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
                                            }
                                            onClick={() => { navigate(`/profile/update?page=2`); }}
                                        >
                                            Upload
                                        </B400>
                                    </Card>
                                </Card>
                            </Grid>}
                        
                    </Grid>
                </Card>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginTop: "1.25rem", paddingLeft: 0 }}>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `0px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >

                    {startupDetail?.elevator_pitch_path ?
                            <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '0' }}>
                                    <CardHeader title={"Video Pitch"}
                                        action={() => { navigate(`/profile/update?page=2`) }}
                                    // sx={{ fontSize: "1rem" }}
                                    />
                                    <ThumbnailGenerator height={300} type="video" url={startupDetail?.elevator_pitch_path} />
                                </Card>
                            </Grid> : <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '0' }}>
                                    <CardHeader title={"Video Pitch"} action={() => { navigate(`/profile/update?page=2`) }}
                                    // sx={{ fontSize: "1rem" }}
                                    />
                                    <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 284 }}>
                                        <B400
                                            sx={{
                                                background: theme.palette.customColors.indigo700,
                                                padding: "0.625rem 1rem"
                                            }}
                                            startIcon={
                                                <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
                                            }
                                            onClick={() => { navigate(`/profile/update?page=2`); }}
                                        >
                                            Upload
                                        </B400>
                                    </Card>
                                </Card>
                            </Grid>}
                        
                    </Grid>
                </Card>
            </Grid>}

        </Grid>
    )
}

export default ViewDetails