import BreadcrumbHeader from 'layout/BreadcrumbsHeader';
import React from 'react'
import ThemeBGWrapper from 'ui-components/ThemeBGWrapper';
import PerfectScrollbar from "react-perfect-scrollbar"
import { containerRef } from 'utilities/index';
import { Box, Card, CardContent, CardHeader, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import BarChart from 'ui-components/BarChart';
import LineChart from 'ui-components/LineChart';
import { B300 } from 'ui-components/CustomButton';
import { Add, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Performance = () => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()

    const charts = [
        {
            name: 'Monthly Active User',
            percentages: [
                { label: 'Apr. 2024', percentage: '100' },
                { label: 'May 2024', percentage: '150' },
                { label: 'June 2024', percentage: '200' },
                { label: 'July 2024', percentage: '180' }, // Drop
                { label: 'Aug. 2024', percentage: '250' },
                { label: 'Sept. 2024', percentage: '230' }, // Drop
                { label: 'Oct. 2024', percentage: '300' }
            ]
        },
        {
            name: 'Monthly Orders',
            percentages: [
                { label: 'Apr. 2024', percentage: '500' },
                { label: 'May 2024', percentage: '540' },
                { label: 'June 2024', percentage: '620' },
                { label: 'July 2024', percentage: '600' }, // Drop
                { label: 'Aug. 2024', percentage: '650' },
                { label: 'Sept. 2024', percentage: '620' }, // Drop
                { label: 'Oct. 2024', percentage: '700' }
            ]
        },
        {
            name: 'Monthly Revenue',
            currency: 'INR',
            percentages: [
                { label: 'Apr. 2024', percentage: '10000000', currency: 'INR' },
                { label: 'May 2024', percentage: '11000000', currency: 'INR' },
                { label: 'June 2024', percentage: '12000000', currency: 'INR' },
                { label: 'July 2024', percentage: '11500000', currency: 'INR' }, // Drop
                { label: 'Aug. 2024', percentage: '13000000', currency: 'INR' },
                { label: 'Sept. 2024', percentage: '12500000', currency: 'INR' }, // Drop
                { label: 'Oct. 2024', percentage: '14000000', currency: 'INR' }
            ]
        },
        {
            name: 'Monthly Burn',
            currency: 'INR',
            percentages: [
                { label: 'Apr. 2024', percentage: '1000000', currency: 'INR' },
                { label: 'May 2024', percentage: '1100000', currency: 'INR' },
                { label: 'June 2024', percentage: '1050000', currency: 'INR' }, // Drop
                { label: 'July 2024', percentage: '1150000', currency: 'INR' },
                { label: 'Aug. 2024', percentage: '1120000', currency: 'INR' }, // Drop
                { label: 'Sept. 2024', percentage: '1200000', currency: 'INR' },
                { label: 'Oct. 2024', percentage: '1180000', currency: 'INR' } // Drop
            ]
        }
        
    ]

    const Actions = () => {
        return (
            <B300 onClick={() => {navigate("edit_performance")}} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Add fontSize='1.25rem' />}>
                {!isSmallScreen ? "Add Key Metrics" : ''}
            </B300>
        )
    }

    const ChartCard = ({ data: cardData }) => {
        return (
            <Grid item xs={12} sm={6}>
                <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.b50}`, overflow: 'hidden', boxShadow: 'none' }}>
                    <CardHeader
                        sx={{ background: theme.palette.customColors.white, borderBottom: `1px solid ${theme.palette.customColors.b50}`, "& > .MuiCardHeader-avatar": { maxWidth: { xs: "43%", md: "50%" }, marginRight: "8px" }, "& > .MuiCardHeader-action": { maxWidth: "50%" } }}
                        avatar={
                            <Box display={"flex"} sx={{ gap: 1, alignItems: "center" }} maxWidth="100%">
                                <Typography fontWeight={700} width="100%" fontSize={{ xs: "0.8rem", md: "0.9rem" }} color={theme.palette.customColors.g300} sx={{ wordBreak: 'break-all', overflowWrap: "break-word", whiteSpace: "normal" }}>
                                    {cardData?.name && cardData?.name != "" ? cardData?.name : '-'} {cardData?.currency && "(" + cardData?.currency + ")"}
                                </Typography>
                            </Box>
                        }
                        action={
                            <IconButton onClick={() => {navigate("edit_performance")}} sx={{}}>
                                <Edit fontSize={"0.9rem"} />
                            </IconButton>}
                    />
                    <CardContent sx={{ paddingBottom: "0 !important" }}>
                        {cardData.currency ? <BarChart graphData={cardData?.percentages} height='100%' width="100%" /> : <LineChart graphData={cardData?.percentages} height='100%' width="100%" />}
                    </CardContent>
                </Card>
            </Grid>
        )
    }



    return (
        <>
            <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
                <BreadcrumbHeader title="Performance" breadcrumbTitle='Performance' customBackgroundColor={theme.palette.customColors.indigo25} Actions={Actions} />
                <PerfectScrollbar
                    containerRef={containerRef}
                    style={{ height: "calc(100vh - 120px)" }}>
                    <Grid container xs={12} padding={{ xs: "0 0rem 1rem 1rem", md: "0 1rem 2rem 2rem" }} spacing={2} justifyContent={"space-between"}>
                        {
                            charts?.map((chart, index) => <ChartCard data={chart} key={index} />)
                        }
                    </Grid>

                </PerfectScrollbar>
            </ThemeBGWrapper>
        </>
    )
}

export default Performance
