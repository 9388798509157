import React, { useEffect } from "react";
import axios from "axios";
import { WHITEBG } from "../../ui-components/CustomButton";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import linkedInIcon from '../../assets/linkedin_icon.png'
import Cookies from "js-cookie"
import { jwtDecode } from 'jwt-decode'
import { LoginSocialLinkedin } from 'reactjs-social-login';
import { LoginViaEmail } from "api";
import { Cookie } from "@mui/icons-material";
const STATE = 'random_string_for_security';

const LinkedinLogin = ({ loginMethod, setLoginMethod }) => {
  const providerCookies = Cookies.get("provider")
  const currentIP = window.location.host;
  const currentLOcation = window.location.pathname;
  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const [provider, setProvider] = React.useState('');
  const [profile, setProfile] = React.useState(null);
  const { mutateAsync: loginViaEmailAsync, status: LoginViaEmailStatus, error: LoginViaEmailError, isLoading, data: loginViaEmailResponse } = LoginViaEmail();

  const onLoginStart = () => {
    // console.log('Login started');
  };

  const onResolve = async ({ provider, data }) => {
    setProvider(provider);
    setProfile(data);
    const decodedData = jwtDecode(data?.id_token)
    // console.log('Login successful',decodedData);
    Cookies.set("userDetails", JSON.stringify({ first_name: decodedData?.given_name, last_name: decodedData?.family_name }))
    setLoginMethod("linkedin");
    await loginViaEmailAsync({
      "email": decodedData?.email,
      "devices": {
        "device_token": `${Math.random() * 10}`,
        "device_type": "web"
      },
      "last_login_ip": `${currentIP}`,
      "auth_provider": "linkedin"
    }, {
      onSuccess: (response) => {
        Cookies.set("email", decodedData?.email);
        Cookies.set("jwt", response?.data?.data?.userData?.token);
        if(currentLOcation?.includes("register") && searchParams.get("ref")){
          Cookies.set("ref", searchParams.get("ref"))
        }
        Cookies.set("provider", "linkedin")
        // console.log(response?.data?.data?.is_profile_completed,"ddddd")
        // Cookies.set("is_profile_complete", response?.data?.data?.is_profile_completed);
        if (response?.data?.data?.is_profile_completed == true) {
          Cookies.set("is_profile_complete", true)
          navigate(currentLOcation !== "/login" && currentLOcation !== "/verification" && currentLOcation !== "/register" ? currentLOcation : "/");
        }
        else {
          Cookies.set("is_profile_complete", false)
          navigate("/onboarding");
          Cookies.set("step", Number(response?.data?.data?.step))
        }
      },
      onError: () => {
        // setEmailError(true);
      }
    });

  };

  const onReject = (err) => {
    console.error('Login failed', err);
  };
  // console.log(isLoading,loginMethod,"loginMethod")

  return (
    <LoginSocialLinkedin
      client_id={process.env.REACT_APP_LINKEDIN_APP_ID}
      client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET}
      redirect_uri={window.location.origin + '/callback'}
      scope="email profile openid"
      onLoginStart={onLoginStart}
      onResolve={onResolve}
      onReject={onReject}
      isOnlyGetToken
    >
      <WHITEBG
        sx={{ border: `1px solid ${theme.palette.customColors.g75}`, height: '55px', marginBottom: { xs: "2rem", sm: 0 } }}
        fullWidth
      >
        {isLoading && loginMethod == "linkedin" ? <CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /> : <Grid
          display={"flex"}
          gap={1}
          flexDirection={"row"}
          alignItems={"center"}
        >
          <img src={linkedInIcon} width={24} height={24} />
          <Typography
            variant="caption"
            sx={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }}
          >
            Login via LinkedIn
          </Typography>
        </Grid>}
      </WHITEBG>
    </LoginSocialLinkedin>
  );
};

export default LinkedinLogin;


///// Attempt 3
// LinkedInLogin.js
// import React from 'react';
// import { signInWithLinkedIn } from '../../firebase/linkedinAuth';

// const LinkedinLogin = () => {
//   const handleLinkedInLogin = async () => {
//     try {
//       const user = await signInWithLinkedIn();
//       console.log('Logged in user:', user);
//     } catch (error) {
//       console.error('LinkedIn login failed:', error);
//     }
//   };

//   return (
//     <div>
//       <button onClick={handleLinkedInLogin}>
//         Login with LinkedIn
//       </button>
//     </div>
//   );
// };

// export default LinkedinLogin;

