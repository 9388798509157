import toast, { Toaster } from 'react-hot-toast';
import ThemeRoutes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./themes/theme";
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

function App() {

const [params] = useSearchParams()
console.log("params", params.get('is_profile_completed'))

useEffect(() => {
  console.log("App.js")
  if(params.get("token")?.length > 0) {
    if(params.get("token")) {
      Cookies.set("jwt", params.get("token"))
    }
    if(params.get("is_profile_completed")) {
      Cookies.set("is_profile_completed", params.get("is_profile_completed"))
    }
    let currentUrl = window.location?.search
    let newUrl = window.location.href
    console.log("currentUrl", currentUrl)
    newUrl = newUrl.replace(currentUrl, "")
    console.log("currentUrl", newUrl)
    window.location.replace(newUrl)
  }
  // setIsReady(false)
  // fetchConfig()
}, [])

  return (
    <>
      <Toaster position='top-right' />
      <ThemeProvider theme={theme}>
        <ThemeRoutes />
      </ThemeProvider>
    </>
  );
}

export default App;
