import React, { useState } from 'react';
import CustomTable from '../../../ui-components/CustomTable';
import { B300, B400 } from '../../../ui-components/CustomButton';
import { Add, Mail, MailOutline, MailOutlined, MailOutlineRounded, PersonAddAlt, ThumbUp, PublishedWithChanges, CallReceived, PersonRemove, DeleteOutlineRounded, EditOutlined, UpdateOutlined, Upload, GetAppOutlined, Done, DoneRounded, Update, Description, DescriptionOutlined } from '@mui/icons-material';
import { ReactComponent as MoreOptionsIcon } from "../../../assets/rounds_icons/more_solid_icon.svg";
import ModalComponent from '../../../ui-components/ModalComponent';
import InviteInvestorPopup from '../popups/InviteInvestorPopup';
import { ReactComponent as RoundInvestmentIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import { Box, Button, Card, Dialog, Grid, Menu, MenuItem, Paper, Skeleton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import BankDetailsPopup from '../popups/BankDetailsPopup';
import CustomTabs from '../../../ui-components/CustomTabs';
import { makeStyles } from '@mui/styles';
import { TabContext } from '@mui/lab';
import AddInvestmentPopup from '../popups/AddInvestmentPopup';
import AllocateAmountPopup from '../popups/AllocateAmountPopup';
import AddSHADocumentsPopup from '../popups/AddSHADocumentsPopup';
import { ReactComponent as RoundInvestorsIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import AllocateUnits from '../popups/AllocateUnits';
import AddInvestorPopup from '../popups/AddInvestorPopup';
import DeleteConfirmationDialog from '../../../ui-components/popups/ConfirmationPopup';
import { DeleteInvestorFromRoundInvestorListing, FetchRoundInvestorsData, MovetoSelectedStatus } from '../../../api';
import { useParams } from 'react-router-dom';
import AsyncTableListing from '../../../ui-components/AsyncTableListing';
import InvestorMovePopup from '../../../ui-components/popups/ConfirmationPopup';

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        "&": {
            padding: "3rem"
        }
        // '& .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer' : {marginLeft: '2rem', gap: '5rem'}
    }
    //     '& .MuiTabs-root > .Mui-disabled.MuiTabs-scrollButtons': {
    //         display: 'none !important',
    //         transition: 'all 1s ease'
    //     },
    // }
}));

function generateEmail(name) {
    return `${name.toLowerCase().replace(" ", ".")}@example.com`;
}

const CardHeader = ({ title, data: CardHeader, action = () => { } }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={"0.5rem 1rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
    </Box>
}


const RoundInvestorsNew = ({ currency }) => {
    const theme = useTheme()
    const classes = useStyles();
    const [value, setValue] = React.useState("pipeline");
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));


    const [openInvitePopup, setOpenInvitePopup] = React.useState(false);
    const [openBankDetailsPopup, setOpenBankDetailsPopup] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState(null);

    const handleInvitePopupOpen = () => {
        setOpenInvitePopup(true);
    }

    const handleInvitePopupClose = () => {
        setOpenInvitePopup(false);
    }

    const handleInviteInvestor = () => {
        handleInvitePopupClose()
    }

    const handleBankDetailsPopupOpen = () => {
        setOpenBankDetailsPopup(true)
    }

    const handleBankDetailsPopupClose = () => {
        setOpenBankDetailsPopup(false)
    }

    const handleSubmitBankDetails = () => {
        handleBankDetailsPopupClose()
    }

    const [openAddInvestorModal, setOpenAddInvestorModal] = React.useState(false);

    const handleAddInvestorClose = (row) => {
        setCurrentRow(null)
        setOpenAddInvestorModal(false);
    }
    const handleAddInvestorSubmit = () => {
        setOpenAddInvestorModal(false)
        setCurrentRow(null)
    }

    const PipelineColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start', fixed: true },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Lead Investor', accessor: 'lead_investor', minWidth: 150, align: 'center', toggle: true, },
        { Header: 'Interested Amount', accessor: 'interested_amount_ref', minWidth: 150, align: 'center', tooltip: "formatted_interested_amount_date" },
        // { Header: 'Date', accessor: 'date', minWidth: 150, align: 'center' },
        { Header: 'Status', accessor: 'pipeline_status', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100 },
    ];

    const ConfirmedColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start', fixed: true },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
        { Header: 'Lead Investor', accessor: 'lead_investor', minWidth: 150, align: 'center', toggle: true, },
        { Header: 'Interested Amount', accessor: 'interested_amount_ref', minWidth: 150, align: 'center', tooltip: "formatted_interested_amount_date" },
        // { Header: 'Date', accessor: 'date', minWidth: 150, align: 'center' },
        { Header: 'Allocated Amount', accessor: 'allocated_amount_ref', minWidth: 150, align: 'center', tooltip: "formatted_allocated_amount_date" },
        { Header: 'KYC', accessor: 'kyc', minWidth: 100, align: 'center', toggle: true, },
        { Header: 'Agreements Signed', accessor: 'agreement_signed', minWidth: 180, align: 'center', toggle: true, },
        { Header: 'Call for Money', accessor: 'call_for_money', minWidth: 150, align: 'center', toggle: true, },
        { Header: 'Fund Received', accessor: 'fund_received', minWidth: 150, align: 'center', toggle: true, },
        // { Header: 'KYC', accessor: 'kyc', minWidth: 150, align: 'center', clickable: true },
        // { Header: 'Status', accessor: 'status', minWidth: 150, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 150 },
    ];




    const columns = React.useMemo(
        () =>
            //      [
            //     { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start' },
            //     { Header: 'Interested Amount', accessor: 'amountCommitted', minWidth: 100, align: 'center' },
            //     { Header: 'Allocated Amount', accessor: 'amountReceived', minWidth: 100, align: 'center' },
            //     { Header: 'Received Amount', accessor: 'callForMoney', minWidth: 100, align: 'center' },
            //     // { Header: 'Call for Money', accessor: 'callForMoney', minWidth: 100, align: 'center' },
            //     { Header: 'Price per Share', accessor: 'drawdown', minWidth: 100, align: 'center' },
            //     // { Header: 'Drawdown %', accessor: 'drawdown', minWidth: 150 },
            //     // { Header: 'Share %', accessor: 'share', minWidth: 150 },
            //     { Header: 'No. of Shares', accessor: 'units', minWidth: 100, align: 'center' },
            //     { Header: 'Status', accessor: 'status', minWidth: 100, align: 'center' },
            //     { Header: 'Actions', accessor: 'actions', minWidth: 100 },
            // ]
            value === 'pipeline' ? PipelineColumns : ConfirmedColumns
        ,
        [value]
    );

    const { id: round_id } = useParams();
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [sort, setSort] = React.useState(-1);
    const [search, setSearch] = React.useState(-1);
    const { data: transformedData, refetch: refetchListing, isLoading: isLoadingRoundInvestors } = FetchRoundInvestorsData({ id: round_id, value, page, limit, sort })
    // console.log(transformedData, "transformedDatatransformedData")
    const [popupOpen, setPopupOpen] = React.useState(false);

    const Actions = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const openMenu = Boolean(anchorEl);
        const handleClickMenu = (event, index) => {
            setAnchorEl(event.currentTarget);
            // setCurrentIndex(index)
            // console.log(event.currentTarget,"re")
        };
        const handleCloseMenu = (event) => {
            // console.log(event,"event")
            setAnchorEl(null);
            // setCurrentIndex(null);
        };
        return <Box display={"flex"} justifyContent={"center"} gap={1} height={"100%"}>
            {value === "pipeline" ? <B400 sx={{ display: 'flex', marginLeft: 'auto', background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={<Add sx={{ width: 20, height: 20 }} />} onClick={() => handleInvitePopupOpen()}>{isMediumScreen ? "" : "Invite Investor"}</B400>
                : <B400 sx={{ height: 45, display: 'flex', marginLeft: 'auto', background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={<RoundInvestmentIcon style={{ width: 20, height: 15, fill: 'white' }} />} onClick={() => setOpenAddInvestorModal(true)} >{isMediumScreen ? "" : "Add Investor"}</B400>}
            {/* <B400 startIcon={<RoundInvestmentIcon style={{ width: 20, height: 15, fill: 'white' }} />} onClick={() => {
                setPopupOpen(true);
            }} sx={{ display: 'flex', background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }}>{isMediumScreen ? "" : "Add Investment"}</B400> */}
            {/* <Box> */}
            {value === "selected" && transformedData?.data?.length > 0 &&
                <Button sx={{ minWidth: "1rem" }} onClick={(e) => handleClickMenu(e)}>
                    {/* <MoreOptionsIcon /> */}
                    <MoreOptionsIcon style={{ color: theme.palette.customColors.indigo700 }} />
                </Button>
            }
            {value === "pipeline" &&
                <Button sx={{ minWidth: "1rem" }} onClick={(e) => handleClickMenu(e)}>
                    {/* <MoreOptionsIcon /> */}
                    <MoreOptionsIcon style={{ color: theme.palette.customColors.indigo700 }} />
                </Button>
            }
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : 'false'}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        padding: "12px",
                        borderRadius: "8px",
                        "& > ul.MuiList-root": { padding: "0 !important" },
                        bgcolor: theme.palette.customColors.g400,
                        color: theme.palette.customColors.whiteBG,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 11,
                            width: 10,
                            height: 10,
                            bgcolor: theme.palette.customColors.g900,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {value === "pipeline" && <MenuItem disableRipple onClick={() => setOpenAddInvestorModal(true)} sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }}>
                    <RoundInvestmentIcon style={{ width: 20, height: 15, fill: 'white', marginRight: '0.5rem' }} /> Add Investor
                </MenuItem>}
                {/* <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }}>
                    <Upload sx={{ width: 20, height: 20, marginRight: '0.5rem' }} /> Import Investors
                </MenuItem> */}
                {/* {value === "pipeline" && transformedData?.data?.length > 0 &&
                    <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }}>
                        <GetAppOutlined sx={{ width: 20, height: 20, marginRight: '0.5rem' }} /> Export Investors
                    </MenuItem>
                }
                {value === "selected" &&
                    <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }}>
                        <GetAppOutlined sx={{ width: 20, height: 20, marginRight: '0.5rem' }} /> Export Investors
                    </MenuItem>
                } */}
            </Menu>
            {/* </Box> */}
        </Box>
    }

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openMoveInvestorPopup, setOpenMoveInvestorPopup] = useState(false);
    const RowActions = ({ row }) => {

        const isSelected = currentRow?.investor_id === row?.original?.investor_id;
        // code for Delete Popup
        const handleOpenDeletePopup = (selected) => {
            setOpenDeletePopup(true);
            setCurrentRow(selected);
        }
        const handleCloseDeletePopup = () => {
            setOpenDeletePopup(false);
            setCurrentRow(null);
        }
        const {
            mutateAsync: deleteInvestorAsync,
            isLoading: isDeletingInvestor,
            status: deleteInvestorStatus,
            error: deleteInvestorError,
            data: deleteInvestorResponse
        } = DeleteInvestorFromRoundInvestorListing({ round_id });

        const handleDelete = async () => {
            try {
                await deleteInvestorAsync({ payload: { investor_id: currentRow?.investor_id } }).then((succ) => { refetchListing(); handleCloseDeletePopup(); });

            } catch (error) {
                console.error("Error deleting investor:", error);
            }
        };

        // code for Moving investors from pipeline to selected

        const handleOpenMoveInvestorPopup = (selected) => {
            setOpenMoveInvestorPopup(true);
            setCurrentRow(selected);
        }
        const handleCloseMoveInvestorPopup = () => {
            setOpenMoveInvestorPopup(false);
            setCurrentRow(null);
        }

        const {
            mutateAsync: updateStatusAsync,
            isLoading: isMovingInvestor,
            status: updateStatusStatus,
            error: updateStatusError,
            data: updateStatusResponse
        } = MovetoSelectedStatus({ round_id });

        const handleMovetoSelected = async () => {
            try {
                await updateStatusAsync({ payload: { investor_id: currentRow?.investor_id } }).then((succ) => { refetchListing(); handleCloseMoveInvestorPopup(); });

            } catch (error) {
                console.error("Error deleting investor:", error);
            }
        };

        return <>
            {
                isSelected && <DeleteConfirmationDialog open={openDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDelete} heading={"Confirm Delete"} ActionText={"Delete"} subheading={"Are you sure you want to delete ?"} isLoading={isDeletingInvestor} />
            }
            {
                isSelected && <InvestorMovePopup open={openMoveInvestorPopup} handleClose={handleCloseMoveInvestorPopup} handleSubmission={handleMovetoSelected} heading={"Confirm Selection"} ActionText={"Confirm"} subheading={"Are you sure you want to move to selected investors?"} isLoading={isMovingInvestor} />
            }
            <Box display={"flex"} gap={1}>
                {/* <Button sx={{ minWidth: "1rem" }} onClick={() => handleBankDetailsPopupOpen()}>
            
                     <MailOutlineRounded sx={{ color: theme.palette.customColors.indigo700 }} />
                </Button> */}
                {row?.original?.status === "pipeline" && row?.original?.interested_amount != 0 && row?.original?.interested_amount != null && <Tooltip title={value == 'pipeline' ? "Move to Selected" : ""}>
                    <Button sx={{ minWidth: "1rem" }} onClick={() => handleOpenMoveInvestorPopup(row?.original)}>
                        {/* <MoreOptionsIcon /> */}
                        <Done sx={{ color: theme.palette.customColors.indigo700 }} />
                    </Button>
                </Tooltip>
                }

                {/* {value !== "pipeline" &&
                    <>
                        <Tooltip title={"Kyc request, upload"}>
                            <Button sx={{ minWidth: "1rem" }} onClick={() => handleBankDetailsPopupOpen()}>
                               
                                <Update sx={{ color: theme.palette.customColors.indigo700 }} />
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Documents"}>
                            <Button sx={{ minWidth: "1rem" }} onClick={() => handleBankDetailsPopupOpen()}>
                          
                                <DescriptionOutlined sx={{ color: theme.palette.customColors.indigo700 }} />
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Call for money"}>
                            <Button sx={{ minWidth: "1rem" }} onClick={() => handleBankDetailsPopupOpen()}>
                              
                                <MailOutlined sx={{ color: theme.palette.customColors.indigo700 }} />
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Fund Received"}>
                            <Button sx={{ minWidth: "1rem" }} onClick={() => handleBankDetailsPopupOpen()}>
                             
                                <CallReceived sx={{ color: theme.palette.customColors.indigo700 }} />
                            </Button>
                        </Tooltip>
                    </>
                } */}
                <Button sx={{ minWidth: "1rem" }}>
                    {/* <MoreOptionsIcon /> */}
                    <EditOutlined sx={{ color: theme.palette.customColors.indigo700 }} onClick={() => { setOpenAddInvestorModal(true); setCurrentRow(row?.original) }} />
                </Button>
                <Button sx={{ minWidth: "1rem" }} onClick={() => handleOpenDeletePopup(row?.original)}>
                    {/* <MoreOptionsIcon /> */}
                    <DeleteOutlineRounded sx={{ color: theme.palette.customColors.indigo700 }} />
                </Button>
            </Box>
        </>
    }


    const handleTabChange = (event, newValue) => {
        // console.log("value", newValue)
        setValue(newValue);
    };

    const handleSwitch = () => {
        switch (value) {
            case 'invited':
                // <></>
                return <AllocateAmountPopup currency={currency} handleClose={() => handleBankDetailsPopupClose()} handleSubmit={handleSubmitBankDetails} />
            case 'interested':
                return <AllocateAmountPopup currency={currency} handleClose={() => handleBankDetailsPopupClose()} handleSubmit={handleSubmitBankDetails} />
            case 'pipeline':
                return <AllocateUnits currency={currency} handleClose={() => handleBankDetailsPopupClose()} handleSubmit={handleSubmitBankDetails} />
            default:
                return <></>
        }
    }

    return (
        <>
            <ModalComponent open={openInvitePopup} onClose={handleInvitePopupClose} style={{ maxWidth: 640, width: "100%" }}>
                <InviteInvestorPopup handleClose={handleInvitePopupClose} refetchListing={refetchListing} handleSubmit={handleInviteInvestor} />
            </ModalComponent>
            <ModalComponent open={openAddInvestorModal} onClose={handleAddInvestorClose} style={{ maxWidth: 640, width: "100%" }}>
                <AddInvestorPopup currency={currency} refetchListing={refetchListing} value={value} handleClose={handleAddInvestorClose} handleSubmit={handleAddInvestorSubmit} currentRow={currentRow} />
            </ModalComponent>
            {/* <Dialog open={openBankDetailsPopup} onClose={() => { }}>
                <BankDetailsPopup handleClose={handleBankDetailsPopupClose} handleSubmit={handleSubmitBankDetails} />
            </Dialog> */}
            <ModalComponent
                style={{ maxWidth: 640, width: "100%" }}
                //  sx={{ "& > .MuiDialog-container > .MuiPaper-root": { marginInline: 0 } }}
                open={openBankDetailsPopup} onClose={() => { handleBankDetailsPopupClose() }}>
                <Paper sx={{ maxHeight: '100%' }}>{handleSwitch()}</Paper>
            </ModalComponent>
            <Dialog sx={{ "& > .MuiDialog-container > .MuiPaper-root": { marginInline: 0 } }} open={popupOpen} onClose={() => { setPopupOpen(false) }}>
                <AddInvestmentPopup currency={currency} handleClose={() => setPopupOpen(false)} handleSubmit={handleSubmitBankDetails} />
            </Dialog>
            {/* <ModalComponent open={openBankDetailsPopup} onClose={handleBankDetailsPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                
            </ModalComponent> */}
            <TabContext value={value}>
                <Grid container xs={12} pt={1} pb={0} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
                    {/* <Grid item xs={10} lg={10}> */}
                    <Grid item xs={12} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0rem"} >
                        <Grid xs={12} container sx={{ bgcolor: 'white', borderRadius: '8px 8px 0 0', borderInline: `1px solid ${theme.palette.customColors.b50}`, borderBlockStart: `1px solid ${theme.palette.customColors.b50}` }}>
                            <Grid item xs={8}>
                                <CustomTabs
                                    variant="submenu"
                                    titleStyle={{ fontSize: '1rem'}}
                                    tabStyle={{ padding: '0.5rem 1rem 0rem' }}
                                    sx={{ border: 'none' }}
                                    bgColor={"transparent"}
                                    data={
                                        [
                                            { value: "pipeline", label: value === 'pipeline' ? `Pipeline (${transformedData?.count ? transformedData?.count : 0})` : "Pipeline", count: 10, read: false, icon: PublishedWithChanges },
                                            { value: "selected", label: value === 'selected' ? `Selected (${transformedData?.count ? transformedData?.count : 0})` : "Selected", count: 10, read: false, icon: DoneRounded },
                                        ]
                                    } value={value} handleChange={handleTabChange}
                                    isSmallScreen={isSmallScreen} className={classes.scrollTabs} key={"Tabs Tabs"} indicatorFullSize={false} />
                            </Grid>
                            <Grid item xs={4} pt={0.75} pr={1}>
                                <Actions />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* { */}
                    {/* <Grid item xs={12} md={12} pt={"0 !important"} marginInline={"auto"} maxWidth={"100%"}> */}

                    {isLoadingRoundInvestors ?
                        <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                            <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

                            </Box>
                        </Grid>
                        : <Box width={"100%"} paddingLeft={"2rem"} maxWidth={"100%"} overflow={"hidden"} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                            <AsyncTableListing
                                columns={columns}
                                // data={[]}
                                tabValue={value}
                                data={transformedData?.data}
                                // title={"Investors"}
                                // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
                                actions={Actions}
                                page={page}
                                setPage={setPage}
                                limit={transformedData?.limit}
                                setLimit={setLimit}
                                count={transformedData?.count}
                                RowActions={RowActions}
                                sx={{ borderRadius: '0 0 8px 8px', border: `1px solid ${theme.palette.customColors.b50}` }}
                                refetchListing={refetchListing}
                            />
                        </Box>
                    }

                    {/* </Grid>} */}
                    {/* <Grid item xs={2} lg={2}> */}

                </Grid>
            </TabContext>
        </>
    );
};

export default RoundInvestorsNew;
