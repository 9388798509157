import { useTheme } from '@emotion/react'
import BreadcrumbHeader from 'layout/BreadcrumbsHeader'
import React, { useRef } from 'react'
import ThemeBGWrapper from 'ui-components/ThemeBGWrapper'
import PerfectScrollbar from "react-perfect-scrollbar"
import { containerRef, handleFormSubmit } from 'utilities/index'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import { Box, Grid } from '@mui/material'
import * as Yup from "yup";
import ClearIcon from '../../assets/doc_icons/close_icon.svg'
import CustomInputField from 'ui-components/formik/CustomInputField'
import { BLUEBORDER, WHITEBG, B300 as CustomButton, } from 'ui-components/CustomButton'
import { ReactComponent as DeleteIcon } from "../../assets/rounds_icons/delete_icon.svg";
import { ReactComponent as AddIcon } from "../../assets/rounds_icons/plus_icon.svg";
import { useNavigate } from 'react-router-dom'

const EditPerformance = () => {
    const theme = useTheme()
    const navigate = useNavigate()

    const bottomElement = useRef(null);

    const validationObj = {
        key_metrics: Yup.array().of(Yup.object().shape({
            heading: Yup.string().required("Heading is required"),
            properties: Yup.array().of(Yup.object().shape(
                { key: Yup.string().required("Key is required"), value: Yup.string().required("Value is required") },
            )),
        },
        )),
    }

    const validationSchema = Yup.object({ ...validationObj });

    const initialValues = {
        key_metrics: [
            {
                heading: "Monthly Active User",
                properties: [
                    { key: "Apr. 2024", value: "100" },
                    { key: "May 2024", value: "300" },
                    { key: "June 2024", value: "105" },
                    {
                        key: 'July 2024',
                        value: '90'
                    },
                    {
                        key: 'Aug. 2024',
                        value: '250'
                    },
                    {
                        key: 'Sept. 2024',
                        value: '430'
                    },
                    {
                        key: 'Oct. 2024',
                        value: '500'
                    }
                ],
            },
            {
                heading: "Monthly Orders",
                properties: [
                    {
                        key: 'Apr. 2024',
                        value: '400'
                    },
                    {
                        key: 'May 2024',
                        value: '430'
                    },
                    {
                        key: 'June 2024',
                        value: '550'
                    },
                    {
                        key: 'July 2024',
                        value: '610'
                    },
                    {
                        key: 'Aug. 2024',
                        value: '700'
                    },
                    {
                        key: 'Sept. 2024',
                        value: '320'
                    },
                    {
                        key: 'Oct. 2024',
                        value: '490'
                    }
                ],
            },
            {
                heading: 'Monthly Revenue (INR)',
                properties: [
                    {
                        key: 'Apr. 2024',
                        value: '12000000'
                    },
                    {
                        key: 'May 2024',
                        value: '10200000'
                    },
                    {
                        key: 'June 2024',
                        value: '14000000'
                    },
                    {
                        key: 'July 2024',
                        value: '15400000'
                    },
                    {
                        key: 'Aug. 2024',
                        value: '12500000'
                    },
                    {
                        key: 'Sept. 2024',
                        value: '14800000'
                    },
                    {
                        key: 'Oct. 2024',
                        value: '12500000'
                    }
                ]
            },
            {
                heading: 'Monthly Burn (INR)',
                properties: [
                    {
                        key: 'Apr. 2024',
                        value: '1200000'
                    },
                    {
                        key: 'May 2024',
                        value: '1020000'
                    },
                    {
                        key: 'June 2024',
                        value: '1040000'
                    },
                    {
                        key: 'July 2024',
                        value: '1100000'
                    },
                    {
                        key: 'Aug. 2024',
                        value: '1125000'
                    },
                    {
                        key: 'Sept. 2024',
                        value: '1068000'
                    },
                    {
                        key: 'Oct. 2024',
                        value: '1190000'
                    }
                ]
            }
        ],
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: (values) => {
            const { documents: currentDocuments, ...selectedValues } = values;

            const savedData = {
                key_metrics: selectedValues?.key_metrics,
            }

            console.log("savedData", savedData)
            navigate('/performance')
        },
    });

    const handleSubmit = (e) => {
        handleFormSubmit(e, formik)
    };

    const scrollToBottom = () => {

        // Find the first visible validation message
        setTimeout(() => {
            if (bottomElement && bottomElement.current && bottomElement.current.offsetParent !== null) {
                bottomElement.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 10)
    }

    return (
        <>
            <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
                <BreadcrumbHeader title="Key Metrics" breadcrumbTitle='Key Metrics' customBackgroundColor={theme.palette.customColors.indigo25} />
                <PerfectScrollbar
                    containerRef={containerRef}
                    style={{ height: "calc(100vh - 120px)" }}>
                    <Grid container xs={12} padding={{ xs: "0 0rem 1rem 1rem", md: "0 1rem 2rem 2rem" }} spacing={2} justifyContent={"space-between"}>
                        <FormikProvider value={formik}>
                            <Form
                                onSubmit={(event) => event.preventDefault()}
                                style={{ width: "100%", height: "100%", paddingBottom: '1rem' }}
                            >
                                <Box
                                    style={{
                                        width: "100%",
                                        paddingRight: "1rem",
                                        paddingTop: "1rem",
                                        marginTop: 20,
                                        paddingBottom: { xs: "1rem", md: 0 },
                                        minHeight: '55vh',
                                        // maxHeight: '65vh',
                                        overflow: 'hidden auto',
                                    }}
                                    className="scrollbar-container"
                                    id="scrollable-form-round"
                                >
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        paddingBottom={{ xs: 2, md: 0 }}
                                        justifyContent={"space-between"}
                                        columnSpacing={"0.5rem"}
                                    >
                                        <FieldArray
                                            name="key_metrics"
                                            render={(arrayHelpers) => (
                                                <Grid item xs={12} container rowGap={2}>
                                                    {formik.values?.key_metrics &&
                                                        formik.values?.key_metrics?.length > 0 &&
                                                        formik.values?.key_metrics?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <Grid
                                                                        xs={12}
                                                                        item
                                                                        container
                                                                        sx={{
                                                                            background:
                                                                                theme.palette.customColors.whiteBG,
                                                                            position: 'relative'
                                                                        }}
                                                                        border={`1px solid ${theme.palette.customColors.g75}`}
                                                                        padding={"8px"}
                                                                        borderRadius={"8px"}
                                                                        gap={1}
                                                                    >
                                                                        {formik.values?.key_metrics?.length > 1 && <img style={{ position: 'absolute', top: "-0.5rem", right: "-0.5rem", height: "1.2rem", width: "1.2rem", cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                                                            arrayHelpers?.remove(index)
                                                                        }} />}
                                                                        <Grid
                                                                            item
                                                                            container
                                                                            xs={12}
                                                                            sm={12}
                                                                            columnGap={1}
                                                                            height={"min-content"}
                                                                            position={"relative"}
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                            >
                                                                                <CustomInputField
                                                                                    sx={{
                                                                                        borderRadius: "4px",
                                                                                        marginBottom: 0,
                                                                                    }}
                                                                                    placeholder={"Enter heading"}
                                                                                    name={`key_metrics.${index}.heading`}
                                                                                    value={item?.heading}
                                                                                    onChange={(e) =>
                                                                                        formik.setFieldValue(
                                                                                            `key_metrics.${index}.heading`,
                                                                                            e.target.value
                                                                                                ?.charAt(0)
                                                                                                ?.toUpperCase() +
                                                                                            e.target.value?.slice(1)
                                                                                        )
                                                                                    }
                                                                                    fullWidth={true}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <FieldArray
                                                                            name={`key_metrics.${index}.properties`}
                                                                            render={(helpers) => (
                                                                                <>
                                                                                    <Grid
                                                                                        item
                                                                                        container
                                                                                        xs={12}
                                                                                        sm={12}
                                                                                        height={"min-content"}
                                                                                        gap={"0.5rem"}
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                            container
                                                                                            xs={index !== 0 ? 12 : 12}
                                                                                            height={"min-content"}
                                                                                            gap={"0.5rem"}
                                                                                        >
                                                                                            {formik.values?.key_metrics[
                                                                                                index
                                                                                            ]?.properties?.map(
                                                                                                (currentProperty, idx) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Grid
                                                                                                                item
                                                                                                                container
                                                                                                                xs={12}
                                                                                                                display={"flex"}
                                                                                                                spacing={"0.5rem"}
                                                                                                                height={
                                                                                                                    "min-content"
                                                                                                                }
                                                                                                            >
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={12}
                                                                                                                    sm={6}
                                                                                                                    lg={6}
                                                                                                                    height={
                                                                                                                        "min-content"
                                                                                                                    }
                                                                                                                >
                                                                                                                    <CustomInputField
                                                                                                                        sx={{
                                                                                                                            borderRadius:
                                                                                                                                "4px",
                                                                                                                            marginBottom: 0,
                                                                                                                        }}
                                                                                                                        name={`key_metrics.${index}.properties.${idx}.key`}
                                                                                                                        value={
                                                                                                                            currentProperty?.key
                                                                                                                        }
                                                                                                                        placeholder={
                                                                                                                            "Enter key"
                                                                                                                        }
                                                                                                                        onChange={(e) =>
                                                                                                                            formik.setFieldValue(
                                                                                                                                `key_metrics.${index}.properties.${idx}.key`,
                                                                                                                                e.target.value
                                                                                                                                    ?.charAt(
                                                                                                                                        0
                                                                                                                                    )
                                                                                                                                    ?.toUpperCase() +
                                                                                                                                e.target.value?.slice(
                                                                                                                                    1
                                                                                                                                )
                                                                                                                            )
                                                                                                                        }
                                                                                                                        fullWidth={true}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    container
                                                                                                                    xs={12}
                                                                                                                    sm={6}
                                                                                                                    lg={6}
                                                                                                                    display={"flex"}
                                                                                                                    flexWrap={"nowrap"}
                                                                                                                    height={
                                                                                                                        "min-content"
                                                                                                                    }
                                                                                                                >
                                                                                                                    <CustomInputField
                                                                                                                        sx={{
                                                                                                                            borderRadius:
                                                                                                                                "4px",
                                                                                                                            marginBottom: 0,
                                                                                                                            width: idx != 0 ? '97%' : "100%"
                                                                                                                        }}
                                                                                                                        name={`key_metrics.${index}.properties.${idx}.value`}
                                                                                                                        value={
                                                                                                                            currentProperty?.value
                                                                                                                        }
                                                                                                                        placeholder={
                                                                                                                            "Enter value"
                                                                                                                        }
                                                                                                                        onChange={(e) =>
                                                                                                                            formik.setFieldValue(
                                                                                                                                `key_metrics.${index}.properties.${idx}.value`,
                                                                                                                                e.target.value
                                                                                                                                    ?.charAt(
                                                                                                                                        0
                                                                                                                                    )
                                                                                                                                    ?.toUpperCase() +
                                                                                                                                e.target.value?.slice(
                                                                                                                                    1
                                                                                                                                )
                                                                                                                            )
                                                                                                                        }
                                                                                                                        fullWidth={true}
                                                                                                                    />
                                                                                                                    {idx !== 0 && (
                                                                                                                        <BLUEBORDER
                                                                                                                            sx={{
                                                                                                                                padding:
                                                                                                                                    "14px 15px",
                                                                                                                                background:
                                                                                                                                    "white",
                                                                                                                                // marginBlock:
                                                                                                                                //   "8px 4px",
                                                                                                                                border: `1px solid ${theme?.palette?.customColors?.g75}`,
                                                                                                                                borderRadius:
                                                                                                                                    "4px",
                                                                                                                                maxHeight: '3rem'
                                                                                                                            }}
                                                                                                                            onClick={() =>
                                                                                                                                helpers?.remove(
                                                                                                                                    idx
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <DeleteIcon />
                                                                                                                        </BLUEBORDER>
                                                                                                                    )
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={12}
                                                                                            sm={12}
                                                                                            height={"min-content"}
                                                                                        >
                                                                                            <WHITEBG
                                                                                                startIcon={<AddIcon />}
                                                                                                sx={{
                                                                                                    borderRadius: "8px",
                                                                                                    borderWidth: "1px",
                                                                                                    padding:
                                                                                                        "10px 20px 10px 20px",
                                                                                                    marginTop: 1,
                                                                                                    borderColor:
                                                                                                        theme?.palette
                                                                                                            ?.customColors?.g100,
                                                                                                    color:
                                                                                                        theme?.palette
                                                                                                            ?.customColors?.g300,
                                                                                                    fontWeight: 700,
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    helpers.push({
                                                                                                        key: "",
                                                                                                        value: "",
                                                                                                    });
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                Add more
                                                                                            </WHITEBG>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                        )}
                                                    <Grid item xs={12} sm={12} height={"min-content"}>
                                                        <WHITEBG
                                                            startIcon={<AddIcon />}
                                                            sx={{
                                                                borderRadius: "8px",
                                                                borderWidth: "1px",
                                                                padding: "10px 20px 10px 20px",
                                                                marginBottom: 2,
                                                                borderColor:
                                                                    theme?.palette?.customColors?.g100,
                                                                color: theme?.palette?.customColors?.g300,
                                                                fontWeight: 700,
                                                            }}
                                                            onClick={() => {
                                                                arrayHelpers.push({
                                                                    heading: "",
                                                                    properties: [
                                                                        { key: "", value: "" },
                                                                        { key: "", value: "" },
                                                                        { key: "", value: "" },
                                                                    ],
                                                                });
                                                                scrollToBottom()
                                                            }
                                                            }
                                                        >
                                                            Add key metrics
                                                        </WHITEBG>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        />
                                        <Box component={"p"} ref={bottomElement} sx={{ opacity: 0, position: "relative", width: '100%', height: "1px" }}>

                                        </Box>
                                    </Grid>
                                </Box>
                            </Form>
                        </FormikProvider>
                        <Grid
                            item
                            container
                            display={"flex"}
                            xs={12}
                            sm={12}
                            height={"min-content"}
                            gap={"1.5rem"}
                            justifyContent={"center"}
                            padding={{ xs: "0 0rem 1rem 0rem", md: "0 0rem 0rem 0rem" }}
                        >
                            <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                <CustomButton
                                    sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                                    onClick={(e) => {
                                        // formik.handleSubmit();
                                        handleSubmit(e)
                                    }}
                                    fullWidth
                                >
                                    Submit
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </ThemeBGWrapper>
        </>
    )
}

export default EditPerformance
