import { useTheme } from '@emotion/react';
import { Avatar, Box, Chip, Grid, Typography, useMediaQuery, Checkbox, Select, Pagination, MenuItem, Switch, alpha, Paper, Tooltip, TableContainer, Rating } from '@mui/material';
import React from 'react';
import { useTable } from 'react-table';
import PerfectScrollbar from "react-perfect-scrollbar";
import { capitalizedSentence, containerRef, getFileCategory, stringAvatar } from "../utilities";
import { useNavigate, useParams } from 'react-router-dom';
import { WHITEBG } from './CustomButton';
import { ReactComponent as PreviousIcon } from "../assets/table-previous.svg";
import { ReactComponent as DownIcon } from "../assets/table-chevron-down.svg";
import { ReactComponent as NextIcon } from "../assets/table-next.svg";
import NoData from './NoData';
import { styled } from '@mui/styles';
import ModalComponent from './ModalComponent';
import AddInvestorPopup from '../views/rounds/popups/AddInvestorPopup';
import { Add } from '@mui/icons-material';
import LeadInvestorControlPopup from './popups/ConfirmationPopup';
import { EditInvestorPopupPostApi } from '../api/round';
import ViewFeedback from 'views/profile/profileSettings/ViewFeedback';
import ImageIcon from 'common-components/ImageIcon';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#e0e0e0",
        '&:hover': {
            backgroundColor: alpha("#3538CD", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#3538CD",
    },
}));

const StatusChip = ({ cell }) => {
    const [openModal, setOpenModal] = React.useState(false)

    const statusStyleSwitch = (value) => {
        switch (value) {
            case "Invited": return ({ color: '#d4a72d', bgcolor: '#fef8e8' })
            case "Added": return ({ color: '#39739d', bgcolor: '#e1ecf4' });
            default: return ({ bgcolor: "#F9F5FF", color: "#3538CD" })
        }
    }

    return (
        <>
            {/* <ModalComponent open={openModal} onClose={() => setOpenModal(false)} style={{ maxWidth: 600 }}>
                <Paper>
                    {cell.column.id === "documents" ? <AddSHADocumentsPopup /> : <UpdateStatusPopup handleClose={() => setOpenModal(false)} />}
                </Paper>
            </ModalComponent> */}
            <Chip
                variant='filled'
                label={cell?.value}
                sx={{ marginInline: 'auto', ...statusStyleSwitch(cell?.value) }}
            // clickable
            // onClick={() => { setOpenModal(true) }}
            />
        </>
    )
}

const AsyncTableListing = (
    { columns,
        data = [],
        title,
        actions: Actions,
        RowActions = () => { },
        chipText = "",
        emptyTitle = "No Data Available",
        CustomLink = ({ children }) => (<>{children}</>),
        backgroundColor = '#fff',
        page = 1,
        setPage = () => { },
        limit = 20,
        setLimit = () => { },
        pagination = true,
        count,
        sx,
        tabValue = "",
        refetchListing = () => { },
        tableHeadStyles = {}
    }
) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [openAddInvestorModal, setOpenAddInvestorModal] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState(false);

    const handleAddInvestorClose = () => {
        setOpenAddInvestorModal(false)
        setCurrentRow(null)
    }
    const handleAddInvestorSubmit = () => {
        setOpenAddInvestorModal(false)
        setCurrentRow(null)
    }
    const canPreviousPage = page > 1;
    const canNextPage = page < Math.ceil(count / limit);

    const previousPage = () => {
        if (canPreviousPage) {
            setPage(page - 1);
        }
    };

    const nextPage = () => {
        if (canNextPage) {
            setPage(page + 1);
        }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows, // Full data without pagination
        prepareRow,
    } = useTable({
        columns,
        data: Array.isArray(data) ? data : [],
        initialState: { pageIndex: 0 },
    });

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const DocumentIcon = ({ row, value }) => {
        const theme = useTheme();
        // console.log(row,cell.value,"rc")
        return <Box width={isSmallScreen ? 35 : 40} height={isSmallScreen ? 35 : 40} bgcolor={theme?.palette?.customColors?.indigo100} borderRadius={"50%"} display={"flex"} alignItems="center" justifyContent={"center"}>
            <img src={getFileCategory(value)} width={20} height={20} />
        </Box>
    }

    const CustomToggle = ({ cell, row }) => {
        const { status, ...currentRow } = row?.original
        const { id: round_id } = useParams();
        const currentColumn = cell.column.id;

        const {
            mutateAsync: editInvestorAsync,
            isLoading: isUpdatingInvestor,
            status: editInvestorStatus,
            error: editInvestorError,
            data: editInvestorResponse
        } = EditInvestorPopupPostApi();

        // Construct details object dynamically based on the column being updated

        const [openModal, setOpenModal] = React.useState(false);

        const handleClose = () => {
            setOpenModal(false);
        };

        const handleSubmission = async () => {
            currentRow[currentColumn] = !(cell.value);
            await editInvestorAsync({ payload: currentRow, _id: round_id }).then(() => {
                refetchListing();
                handleClose();
            })
        };

        const shouldRenderModal = [
            "lead_investor",
            "kyc",
            "agreement_signed",
            "call_for_money",
            "fund_received"
        ].includes(currentColumn);

        return (
            <>
                {shouldRenderModal && (
                    <LeadInvestorControlPopup
                        open={openModal}
                        handleClose={handleClose}
                        handleSubmission={handleSubmission}
                        heading={`Update ${capitalizedSentence(currentColumn.replaceAll("_", " "))} Status`}
                        ActionText="Confirm"
                        subheading={`Are you sure you want to update ${currentColumn.replaceAll("_", " ")} status?`}
                    />
                )}
                <CustomSwitch
                    checked={cell.value}
                    cell={cell}
                    row={currentRow}
                    onClick={() => setOpenModal(true)}
                />
            </>
        );
    };

    return (
        <>
            <ModalComponent open={openAddInvestorModal} onClose={handleAddInvestorClose} style={{ maxWidth: 640, width: "100%" }}>
                <AddInvestorPopup currentRow={currentRow} value={tabValue} handleClose={handleAddInvestorClose} handleSubmit={handleAddInvestorSubmit} refetchListing={refetchListing} />
            </ModalComponent>
            {data?.length > 0 ? <Grid container xs={12} className='table-container' border={`1px solid ${theme.palette.customColors.gray200}`} borderRadius={sx ? sx?.borderRadius : '8px'} bgcolor={backgroundColor}
                maxHeight={"70vh"}
                height={"100%"} overflow={"hidden"}>
                {title && <Grid container item xs={12} display={"flex"} justifyContent={"space-between"}
                    padding={"1rem 1.5rem"} alignItems={"center"}>
                    <Box display={"flex"} alignItems={"center"} gap={"0.5rem"}>
                        <Typography color={theme.palette.customColors.g300} sx={{ fontSize: '18px', fontWeight: 500, lineHeight: "28px", textAlign: 'left' }}>
                            {title}
                        </Typography>
                        {chipText && <Chip label={chipText} size='small' sx={{ padding: '0.125rem 0.5rem !important', color: "#6941C6", bgcolor: '#F9F5FF', "& > .MuiChip-label": { padding: 0 } }} />}
                    </Box>
                    {Actions && <Actions />}
                </Grid>}

                {/* <PerfectScrollbar containerRef={containerRef} style={{ height: '100%', maxHeight: matchesUpMd ? '44vh' : '40vh', width: '100%' }}> */}
                <PerfectScrollbar containerRef={containerRef} style={{ height: '100%', maxHeight: pagination ? (matchesUpMd ? '85%' : '88%') : "100%", width: '100%' }}>
                    {/* <TableContainer sx={{ height: '100%', maxHeight: matchesUpMd ? '100%' : '100%', width: '100%' }}> */}
                    <table style={{ width: '100%' }} {...getTableProps()}>
                        <thead style={{ position: 'sticky', zIndex: 1000, top: 0, backgroundColor: theme.palette.background.default, ...tableHeadStyles }}>
                            {headerGroups?.map(headerGroup => (
                                <tr
                                    key={headerGroup.id}
                                    style={{
                                        fontSize: "12px", fontWeight: 500, lineHeight: '18px', textAlign: "left",
                                        color: theme.palette.customColors.g100
                                    }}
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup?.headers?.map((column, index) => {
                                        if (column?.id === 'checkbox') {
                                            return (
                                                <th style={{ minWidth: column?.minWidth, textAlign: column?.align, position: column.fixed ? 'sticky' : 'initial', left: column.fixed ? 0 : '', zIndex: column.fixed ? 1 : 0 }} align={column?.align} key={column.id} {...column.getHeaderProps()}><Checkbox style={{ height: 20, width: 20, color: "#7c4dff" }} /></th>
                                            )
                                        }
                                        else if (column?.id === 'report_actions' && count > 2) {
                                            return (
                                                <th style={{ minWidth: column?.minWidth, textAlign: column?.align, position: column.fixed ? 'sticky' : 'initial', left: column.fixed ? 0 : '', zIndex: column.fixed ? 1 : 0 }} align={column?.align} key={column.id} {...column.getHeaderProps()}>
                                                    {data?.length > 0 && <Typography
                                                        onClick={() => { navigate(`/reports/edit_report/${data[0]?.report_id}`) }}
                                                        sx={{
                                                            textAlign: "center",
                                                            color: theme.palette.customColors.indigo600,
                                                            fontSize: '0.75rem',
                                                            cursor: 'pointer'
                                                        }}
                                                    >View All ({count})</Typography>}
                                                </th>
                                            )
                                        }
                                        return (
                                            <th style={{ minWidth: column?.minWidth, textAlign: column?.align, position: column.fixed ? 'sticky' : 'initial', left: column.fixed ? 0 : '', zIndex: column.fixed ? 1 : 0 }} align={column?.align} key={column.id} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        )
                                    })}


                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows?.length > 0 && rows?.map((row, idx) => {
                                prepareRow(row);
                                return (
                                    <tr key={row.id} {...row.getRowProps()}>
                                        {row?.cells?.map((cell, index) => {
                                            if (cell.column?.id === 'checkbox') {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align }} align={cell.column?.align}><Checkbox style={{ height: 20, width: 20, color: "#7c4dff" }} sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            borderRadius: '8px',  // Change the border-radius value here
                                                        }
                                                    }} /></td>
                                                )
                                            }
                                            if (cell.column.id === "name") {
                                                {/* console.log(cell.row.original.image || cell.row.original?.profile_picture,"efef") */ }  
                                                if (CustomLink) {
                                                    return (
                                                        <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                            <CustomLink row={row} cell={cell}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px', cursor: 'pointer'
                                                                    }}>
                                                                    {cell.row.original.image ? <ImageIcon src={cell.row.original.image} style={{borderRadius: '4px'}} alt={""} /> : <Avatar className='avatar-investor' sx={{}}  {...stringAvatar(cell.value)} />}
                                                                    <Box>
                                                                        {cell?.value && cell?.value != "" && <Typography fontWeight={600}>
                                                                            {cell.value}
                                                                        </Typography>
                                                                        }<Typography fontSize={cell?.value && cell?.value != "" ? "0.7rem" : '0.9rem'}>
                                                                            {cell.row.original.email}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </CustomLink>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px', cursor: cell.row.original.public_profile_url ? 'pointer' : 'default'
                                                        }} onClick={() => { cell.row.original.public_profile_url && window.open(cell.row.original.public_profile_url) }}>
                                                            {cell.row.original.image || cell.row.original?.profile_picture ? <img src={cell.row.original.image || cell.row.original?.profile_picture} style={{ width: 40, height: 40, background: "#F9F5FF", borderRadius: '4px', objectFit: 'contain' }} /> : <Avatar className='avatar-investor' {...stringAvatar(cell.value)} />}
                                                            <Box>
                                                                {cell?.value && cell?.value != "" && <Typography fontWeight={cell.row.original.public_profile_url && 700}>
                                                                    {cell.value}
                                                                </Typography>
                                                                }<Typography fontSize={cell?.value && cell?.value != "" ? "0.7rem" : '0.9rem'}>
                                                                    {cell.row.original.email}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </td>
                                                );
                                            }

                                            else if (cell.column.id === "document_name") {
                                                const extension = cell?.row?.original?.filename?.split(".")?.pop();
                                                if (cell.column?.trim) {
                                                    if(CustomLink) {
                                                        return (
                                                            <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                                <CustomLink row={row} cell={cell}>
                                                                    <Box sx={{
                                                                        display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px' , cursor:'pointer',
                                                                    }}>
                                                                        <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {String(cell.value)?.substring(0, cell.column?.trim)}
                                                                    </Box>
                                                                </CustomLink>
                                                            </td>
                                                        );
                                                    }
                                                    return (
                                                        <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                            <Box sx={{
                                                                display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                            }}>
                                                                <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {String(cell.value)?.substring(0, cell.column?.trim)}
                                                            </Box>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box onClick={() => window.open(cell?.row?.original?.document_url)} sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px', cursor: 'pointer'
                                                        }}>
                                                            <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {String(cell.value)?.substring(0, 50) + "." + extension}
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.id === "filename") {
                                                const extension = cell?.row?.original?.filename?.split(".")?.pop();
                                                if (cell.column?.trim) {
                                                    return (
                                                        <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                            <Box sx={{
                                                                display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                            }}>
                                                                <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {cell.render('Cell')}
                                                            </Box>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {cell.render('Cell')}
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.id === "actions" || cell.column.id === "report_actions") {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <RowActions cell={cell} row={row} />
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.id === "status" || cell.column.id === "pipeline_status" || cell.column.clickable) {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <StatusChip cell={cell} row={row} />
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (["problem", "market", "product", "team", "traction"]?.includes(cell.column.id)) {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <Rating value={cell?.value} precision={0.5} readOnly />
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.toggle) {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', justifyContent: cell.column?.align, color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <CustomToggle cell={cell} row={row} checked={cell.value} />

                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            if (cell.column.id === "statusWithDate") {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}><Typography>{cell.value}</Typography></td>
                                                );
                                            }
                                            if (cell.column.id === "interested_amount_ref" || cell.column.id === "allocated_amount_ref") {
                                                const tooltipField = cell?.column?.tooltip;
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>

                                                        <Box sx={{
                                                            display: 'grid', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            {cell.row?.original?.status != "Invited" && cell?.value != "" && cell?.value && cell?.value != null && String(cell?.value)?.trim()?.length !== 0 ?
                                                                <Tooltip title={row?.original[tooltipField]}>
                                                                    <Box>
                                                                        <Typography>
                                                                            {cell.value}
                                                                        </Typography>
                                                                        <Typography fontSize={"0.7rem"}>
                                                                            {cell.row.original.date}
                                                                        </Typography>
                                                                    </Box>
                                                                </Tooltip> : <Box onClick={() => { setOpenAddInvestorModal(true); setCurrentRow(cell.row?.original) }} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ textDecoration: "underline", textUnderlineOffset: '5px', textDecorationColor: "#3538CD", cursor: 'pointer' }}>
                                                                    <Add style={{ width: 20, height: 20, color: "#3538CD" }} />
                                                                    <Typography fontSize={"0.9rem"} color={"#3538CD"}> Add </Typography>
                                                                </Box>}
                                                        </Box>
                                                    </td>
                                                );
                                            }

                                            if (cell?.column?.tooltip) {
                                                const tooltipField = cell?.column?.tooltip;
                                                if (cell?.value == undefined || cell?.value == null || String(cell?.value)?.trim()?.length === 0) {
                                                    return (
                                                        <td
                                                            key={index}
                                                            style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }}
                                                            align={cell.column?.align}
                                                            {...cell.getCellProps()}>
                                                            -
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td
                                                        key={index}
                                                        style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }}
                                                        align={cell.column?.align}
                                                        {...cell.getCellProps()}>
                                                        <Tooltip title={row?.original[tooltipField]}>
                                                            {cell.value}
                                                        </Tooltip>
                                                    </td>
                                                );
                                            }
                                            if (cell?.value == undefined || cell?.value == null || String(cell?.value)?.trim()?.length === 0) {
                                                return (
                                                    <td
                                                        key={index}
                                                        style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }}
                                                        align={cell.column?.align}
                                                        {...cell.getCellProps()}>
                                                        {/* {cell.render('Cell')} */}
                                                        -
                                                    </td>
                                                );
                                            }

                                            return (
                                                <td
                                                    key={index}
                                                    style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }}
                                                    align={cell.column?.align}
                                                    {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {/* {rows?.length === 0 && (
                                (
                                    <tr>
                                        <td colSpan={columns?.length > 0 ? columns?.length : 1} align='center' style={{ textAlign: 'center' }}>{emptyTitle}</td>
                                    </tr>
                                )
                            )} */}
                        </tbody>
                    </table>
                    {/* </TableContainer> */}
                </PerfectScrollbar>
                {pagination && (
                    <Grid container item xs={12} sx={{
                        padding: '12px 24px 16px 24px', display: 'grid', gridTemplateAreas: isSmallScreen ? `"previous page next" "number number number"` : `"previous number page span next"`, gridTemplateColumns: isSmallScreen ? '1fr 2fr 1fr' : '1fr 1fr 10fr 1fr 1fr',
                        gridTemplateRows: isSmallScreen ? '1fr 1fr' : '1fr', borderTop: `1px solid ${theme.palette.customColors.gray200}`, position: 'sticky', bottom: "-1px", background: theme.palette.customColors.whiteBG,
                        rowGap: 1, columnGap: 1, alignItems: 'center',
                        zIndex: 1000
                    }}>
                        <Grid item display={"flex"} gap={"0.5rem"} gridArea={'previous'}>
                            <WHITEBG onClick={() => previousPage()} disabled={!canPreviousPage}
                                sx={{
                                    minWidth: "0 !important",
                                    border: `1px solid #D0D5DD !important`,
                                    padding: "8px 14px",
                                    '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' }
                                }}
                                startIcon={<PreviousIcon stroke={!canPreviousPage ? '#D0D5DD' : '#344054'} />}
                            >
                                {isSmallScreen ? '' : "Previous"}
                            </WHITEBG>
                        </Grid>
                        <Grid item gridArea={'number'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Select
                                value={limit}
                                onChange={(event) => {
                                    setLimit(event.target.value);
                                    setPage(1);
                                }}
                                size="small"
                                IconComponent={DownIcon}
                                sx={{
                                    minWidth: 150,  // Adjust the width as needed
                                    borderRadius: '8px',
                                    border: '1px solid #D0D5DD',  // Border similar to the one in the image
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#344054',  // Text color
                                    '& .MuiSelect-select': {
                                        padding: '8px 12px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        marginTop: 0,
                                        color: '#344054',  // Arrow icon color
                                    },
                                    '& > .MuiSelect-icon': {
                                        top: '25%'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',  // Remove the default border
                                    },
                                }}
                            >
                                {[10, 20, 25, 30, 40, 50]?.map((size) => (
                                    <MenuItem key={size} value={size}>
                                        {size} Per page
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item display={"flex"} justifyContent={"center"} gridArea={'page'}>
                            <Pagination
                                count={Math.ceil(count / limit)}
                                page={page}
                                onChange={(e, value) => setPage(value)}
                                shape="rounded"
                                hideNextButton
                                hidePrevButton
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        color: '#6c757d', // Inactive page color
                                        fontWeight: '500',
                                    },
                                    '& .MuiPaginationItem-root.Mui-selected': {
                                        backgroundColor: 'rgba(138, 75, 244, 0.1)', // Light purple background
                                        color: '#8A4BF4', // Active page color
                                    },
                                    '& .MuiPaginationItem-ellipsis': {
                                        color: '#6c757d', // Ellipsis color
                                    },
                                }}  
                            />
                        </Grid>
                        <Grid item gridArea={'number'} display={"flex"} alignItems={"center"} justifyContent={"center"}></Grid>
                        <Grid item display={"flex"} flexDirection={"row-reverse"} gridArea={'next'}>
                            <WHITEBG onClick={() => nextPage()} disabled={!canNextPage}
                                sx={{
                                    minWidth: "0 !important",
                                    border: `1px solid #D0D5DD !important`,
                                    padding: "8px 14px",
                                    '& > .MuiButton-icon.MuiButton-endIcon': { marginRight: isSmallScreen ? 0 : '-4px', marginLeft: isSmallScreen ? 0 : '8px' }
                                }}
                                endIcon={<NextIcon stroke={!canNextPage ? '#D0D5DD' : '#344054'} />}
                            >
                                {isSmallScreen ? '' : 'Next'}
                            </WHITEBG>
                        </Grid>
                    </Grid>
                )}
            </Grid> : <NoData Actions={Actions} sx={{ backgroundColor: theme?.palette.customColors?.whiteBG, ...sx }} />}
        </>
    );
};

export default AsyncTableListing;
