import React from 'react';
import AuthWrapper1 from '../../ui-components/AuthWrapper1';
import googleIcon from '../../assets/google_icon.png';
import linkedInIcon from '../../assets/linkedin_icon.png';
import appleIcon from '../../assets/apple_icon.png';
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import CustomBackgroundComponent from '../../ui-components/CustomBackgroundComponent';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage';
import BackrrLogo from 'assets/Backrr_logo_symbol_white.webp';
import { useTheme } from '@emotion/react';
import CustomInputField from '../../ui-components/CustomInputField';
import { B300 as CustomButton, WHITEBG } from '../../ui-components/CustomButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoginViaEmail } from '../../api';
import toast from 'react-hot-toast';
import CustomToast from '../../ui-components/CustomToast';
import Cookies from "js-cookie";
import { auth, provider, signInWithPopup, signInWithRedirect, signOut } from "../../firebase";
import * as jwt_decode from 'jwt-decode';
import LinkedinLogin from './LinkedinLogin';
import CustomHeaderIcon from 'ui-components/CustomHeaderIcon';

const AuthLogin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentIP = window.location.host;
  const currentLOcation = window.location.pathname;
  const [searchParams, setSearchParams] = useSearchParams()
  // console.log(currentLOcation,"currentLOcation",searchParams.get("ref"))
  const [userData, setUserData] = React.useState(null);
  const [loginMethod, setLoginMethod] = React.useState("email");
  const { mutateAsync: loginViaEmailAsync, status: LoginViaEmailStatus, error: LoginViaEmailError, isLoading, data: loginViaEmailResponse } = LoginViaEmail();

  React.useEffect(() => {
    window.document.getElementsByTagName("body")[0].style.background = "#FFFAF5";
  }, [])

  React.useEffect(() => {
    if (loginViaEmailResponse && loginViaEmailResponse?.data?.statusCode === 200) {
      Cookies.get("email") && navigate("/verification");
      // else {
      //   loginViaEmailResponse?.data?.data?.is_profile_completed == true ? navigate("/") : navigate("/onboarding");
      // }
      // console.log(loginViaEmailResponse?.data?.data?.is_profile_completed,loginViaEmailResponse?.data?.data?.is_profile_complete == "true")

    }
    if (loginViaEmailResponse && loginViaEmailResponse?.data?.statusCode !== 200) {
      toast.error(<CustomToast message={loginViaEmailResponse?.data.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />, {
        duration: 4000,
        position: 'top-right',
        style: {
          padding: "15px 30px"
        }
      });
    }
  }, [loginViaEmailResponse?.data?.statusCode]);

  const [emailValue, setEmailValue] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const handleLogin = () => {
    setLoginMethod("email");
    loginViaEmailAsync({
      "email": emailValue,
      "devices": {
        "device_token": `${Math.random() * 10}`,
        "device_type": "web"
      },
      "last_login_ip": `${currentIP}`,
      "auth_provider": "email"
    }, {
      onSuccess: (response) => {
        Cookies.set("email", emailValue);
        Cookies.set("step", Number(response?.data?.data?.step))
        if(currentLOcation?.includes("register") && searchParams.get("ref")){
          Cookies.set("ref", searchParams.get("ref"))
        }
      },
      onError: () => {
        setEmailError(true);
      }
    });
  };

  React.useEffect(() => {
    const initAppleSignIn = () => {
      window.AppleID.auth.init({
        clientId: 'in.backrr.startup',
        scope: "name%20email",
        redirectURI: "https://startup.backrr.in",
        state: 'SignInUserAuthenticationRequest',
        usePopup: true
      });
    };

    if (window.AppleID) {
      initAppleSignIn();
    } else {
      const script = document.createElement('script');
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.onload = initAppleSignIn;
      document.body.appendChild(script);
    }
  }, []);

  const handleSignIn = async () => {
    await window.AppleID.auth.signIn().then((res) => {
      const id_token = res.authorization.id_token;
      // console.log(res, "res")
      const user = jwt_decode.jwtDecode(id_token);
      // console.log(user, "user")
      setLoginMethod("appleid");
      loginViaEmailAsync({
        "email": user?.email,
        "devices": {
          "device_token": `${Math.random() * 10}`,
          "device_type": "web"
        },
        "last_login_ip": `${currentIP}`,
        "auth_provider": "appleid"
      }, {
        onSuccess: (response) => {
          Cookies.set("jwt", response?.data?.data?.userData?.token);
          Cookies.set("email", user?.email)
          if(currentLOcation?.includes("register") && searchParams.get("ref")){
            Cookies.set("ref", searchParams.get("ref"))
          }
          // Cookies.set("is_profile_complete", response?.data?.data?.is_profile_completed);
          if (response?.data?.data?.is_profile_completed == true) {
            Cookies.set("is_profile_complete", true)
            navigate("/");
          }
          else {
            Cookies.set("is_profile_complete", false)
            Cookies.set("step", Number(response?.data?.data?.step))
            navigate("/onboarding");
          }
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleSignInGoogle = async () => {
    setLoginMethod("google");
    await signOut(auth);
    await signInWithPopup(auth, provider)
      .then((result) => {
        // console.log(result, "result?.user");
        Cookies.set("userDetails", JSON.stringify({ first_name: result?._tokenResponse?.firstName, last_name: result?._tokenResponse?.lastName, mobile_number: result?.user?.phoneNumber }))
        loginViaEmailAsync({
          "email": result?.user?.email,
          "devices": {
            "device_token": `${Math.random() * 10}`,
            "device_type": "web"
          },
          "last_login_ip": `${currentIP}`,
          "auth_provider": "google"
        }, {
          onSuccess: (response) => {
            Cookies.set("jwt", response?.data?.data?.userData?.token);
            Cookies.set("email", result?.user?.email)
            if(currentLOcation?.includes("register") && searchParams.get("ref")){
              Cookies.set("ref", searchParams.get("ref"))
            }
            // Cookies.set("is_profile_complete", response?.data?.data?.is_profile_completed);
            if (response?.data?.data?.is_profile_completed == true) {
              // console.log(currentLOcation,"path")
              Cookies.set("is_profile_complete", true)
              navigate(currentLOcation !== "/login" && currentLOcation !== "/verification" && currentLOcation !== "/register" ? currentLOcation : "/");
            }
            else {
              Cookies.set("is_profile_complete", false)
              // navigate("/onboarding?page=" + Number(response?.data?.data?.step));
              navigate("/onboarding");
              Cookies.set("step", Number(response?.data?.data?.step))
            }
          }
        });
      })
      .catch((error) => {
        console.error('Error signing in:', error);
      });
  };

  const handleSubmit = () => {
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern?.test(emailValue)) {
      handleLogin();
    }
    else {
      setEmailError(true);
    }
  };

  return (
    <>
      {/* <AuthWrapper1 xs={12} container>
        <Grid xs={12} container display={"flex"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
          maxWidth: '1440px !important', marginInline: 'auto',
          maxHeight: 960
        }}>
          <Grid item xs={12} sm={12} md={6.6} display={"grid"} sx={{ padding: { xs: "1rem", sm: "1rem 2rem", md: "2rem 2rem", lg: "2rem 5rem" } }}> */}
      <Grid item xs={12} display={"flex"} flexDirection={"column"} maxHeight={"736px"} gap={0} minHeight={{ xs: "100vh", sm: "100%" }} overflowY={{ xs: "auto", sm: 'hidden' }} sx={{ paddingBottom: "2rem" }}>
        {/* <BackrrLogoImage /> */}
        <CustomHeaderIcon customIcon={BackrrLogo} />
        <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }} marginTop={1} textAlign={{ xs: "center", md: 'start' }}>
          {/* Login to your account */}
          Backrr for Startups
        </Typography>
        <Typography variant='bodyTextRegular' color={theme.palette.customColors.g100} marginTop={1} sx={{ textAlign: { xs: 'center', md: "start" } }}>
          {/* Start your startup journey now! */}
          Login or signup with options below
        </Typography>
        <CustomInputField sx={{ margin: 0 }} value={emailValue} onKeyDown={(e) => { if (e.key === "Enter") { handleSubmit(); } }} onChange={(event) => {
          (event.target.value)?.length <= 60 && setEmailValue(String(event.target.value)?.toLowerCase()); setEmailError(false);
        }} hasError={emailError} errorMessage={emailValue?.length > 0 ? "Please enter a valid email." : "Email is required."} labelStyle={{ marginTop: "1rem" }} fullWidth label="EMAIL" type="email" placeholder='' />
        <Grid display={"grid"} gap={"1rem"}>
          <CustomButton disabled={isLoading && loginMethod === "email"} sx={{ background: theme.palette.customColors.B300, borderRadius: "8px", textTransform: "none", height: '55px', fontSize: '1rem', marginTop: "1rem" }} fullWidth onClick={() => {
            handleSubmit();
          }}>
            {isLoading && loginMethod === "email" ?
              <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} />
              : "Next"}
          </CustomButton>
          <Grid position={'relative'}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Divider sx={{ flex: 1 }} />
            <Typography sx={{ width: "2rem", textAlign: "center" }}>or</Typography>
            <Divider sx={{ flex: 1 }} />
          </Grid>
          <WHITEBG sx={{ border: `1px solid ${theme.palette.customColors.g75}`, height: '55px' }} onClick={() => handleSignInGoogle()} fullWidth>
            {isLoading && loginMethod === "google" ? <CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /> : <Grid display={'flex'} gap={1} flexDirection={"row"} alignItems={'center'}>
              <img src={googleIcon} width={24} height={24} />
              <Typography variant="caption" sx={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }}>
                Login via Google
              </Typography>
            </Grid>}
          </WHITEBG>
          <LinkedinLogin userData={userData} setUserData={setUserData} signin={loginViaEmailAsync} loginMethod={loginMethod} setLoginMethod={setLoginMethod} isLoading={isLoading} />
          {/* <WHITEBG onClick={handleSignIn} data-border="true" data-type="sign in" sx={{
                border: `1px solid ${theme.palette.customColors.g75}`, height: '55px'
              }} fullWidth> {isLoading && loginMethod === "appleid" ? <CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /> : <Grid display={'flex'} gap={1} flexDirection={"row"} alignItems={'center'}> <img src={appleIcon} width={24} height={24} /> <Typography variant="caption" sx={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }}> Login via Apple ID </Typography> </Grid>} </WHITEBG> */}

        </Grid>
      </Grid>
      {/* </Grid>
          <Grid item xs={12} md={5.4} sx={{ display: { xs: 'none', md: 'block' }, position: "relative", padding: "0 !important", height: "100%" }}>
            <CustomBackgroundComponent md={12} lg={12} xl={12} />
          </Grid>
        </Grid>
      </AuthWrapper1> */}
    </>
  );
};

export default AuthLogin